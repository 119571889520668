import { Link } from "@mui/material";
import { abreSimulador } from "@/services/utils";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function ParcelamentoDePixNoCredito() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_COMO_PARCELAR_PIX_NO_CARTAO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog"
                src="/site/blog/blog-parcelamento-de-pix-no-credito.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                Como parcelar PIX no cartão: uma solução inteligente para quem
                deseja mais facilidade de crédito
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: 20 }}>
                O parcelamento de PIX pode ser a melhor solução para quem busca
                mais flexibilidade no pagamento de suas obrigações. Neste
                artigo, vamos explicar como você pode parcelar utilizando a
                solução <span className="quita">Quita Boletos</span>. Leia até o
                final para entender como funciona.
              </Typography>
            </Grid>

            <Grid item container spacing={2} className="sessao">
              <Grid item xs={12}>
                <Typography variant="h3">Como funciona o PIX?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20, pb: 1 }}>
                  O PIX é um sistema de pagamentos instantâneos que permite
                  transferir dinheiro entre contas bancárias de forma rápida e
                  segura. Além de pagamentos à vista, o PIX também pode ser
                  utilizado no formato parcelado. Com a{" "}
                  <span className="quita">Quita Boletos</span>, você parcela o
                  PIX em até 12 vezes no cartão de crédito, podendo utilizar
                  mais de um cartão para completar o valor total da operação.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Vantagens do parcelamento de PIX:
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ fontSize: 20 }}>
                <ol>
                  <li>
                    <b>Flexibilidade financeira:</b> Distribua o valor total do
                    PIX em várias parcelas, de acordo com o seu orçamento.
                  </li>
                  <li>
                    <b>Melhor gestão financeira:</b> Planeje e gerencie suas
                    finanças com mais facilidade, evitando grandes desembolsos
                    únicos.
                  </li>
                </ol>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Com a <span className="quita">Quita Boletos</span>, você
                  parcela de forma rápida, segura e 100% online. Veja como é
                  simples:
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ fontSize: 20 }}>
                <ol>
                  <li>
                    Você pode simular clicando{" "}
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => abreSimulador()}>
                      AQUI{" "}
                    </Link>{" "}
                    ou em SIMULAR.
                  </li>
                  <li>
                    Insira o valor total que deseja parcelar, preencha seus
                    dados e clique em <b>Próximo</b>.
                  </li>
                  <li>Escolha a quantidade de parcelas desejada.</li>
                  <li>
                    Selecione a opção PIX e insira os dados necessários para
                    prosseguir.
                  </li>
                  <li>
                    Para finalizar, insira os dados do cartão de crédito e
                    clique em <b>Pagar agora</b>.
                  </li>
                </ol>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  <b>Importante:</b> <br />
                  Utilize apenas cartões de crédito com limite disponível para o
                  valor total das parcelas.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Viu como é simples? Uma solução prática e segura para quem
                  busca mais flexibilidade financeira.
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular></BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
