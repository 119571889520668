import { Link } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function EndividamentoImpactaPsicologico() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_ENDIVIDAMENTO_NO_BRASIL_E_O_IMPACTO_PSICOLOGICO_CAUSADO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post" justifyContent="center">
        <Grid container justifyContent="center">
          <Grid
            className="logo-blog"
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="sm"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog-img"
                  src="/site/blog/blog-psicologico.jpg"
                  alt="logo-blog"
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  O endividamento no Brasil e o impacto psicológico causado.
                </Typography>
              </Grid>

              <Grid container className="sessao intro">
                <Typography
                  sx={{ fontSize: 20 }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  O endividamento é uma realidade que afeta milhões de
                  brasileiros, e os impactos psicológicos dessa situação podem
                  ser profundos.
                </Typography>
                <Typography
                  sx={{ fontSize: 20 }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  De acordo com a pesquisa comportamental realizada em 2022 pela
                  <Link target="_blank" href="https://www.opinionbox.com/">
                    {" "}
                    OpinionBox.pesquisas
                  </Link>
                  , com mais de 5 mil brasileiros endividados, foi possível
                  identificar as principais questões emocionais enfrentadas por
                  essas pessoas.{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: 20 }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Neste artigo, vamos explorar os resultados desta pesquisa,
                  trazendo números e informações comportamentais relevantes.{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: 20 }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Fique conosco por apenas 5 minutos de leitura e descubra como
                  acabar de vez com o endividamento e com as complicações
                  decorrentes do acúmulo de dívidas que vem gerando desconforto
                  psicológico em você e na sua família.
                </Typography>
              </Grid>

              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    O Estresse e ansiedade financeira
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    De acordo com a pesquisa solicitada pelo Serasa junto ao
                    instituto Opinion box, boa parte dos entrevistados relatou
                    sentir altos níveis de estresse relacionado às suas dívidas.
                    O endividamento gera preocupação constante e a sensação de
                    estar preso em um ciclo difícil de sair.{" "}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Além disso, a ansiedade financeira pode prejudicar o
                    bem-estar emocional e interferir nas relações pessoais e
                    profissionais.
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}>
                    Segundo o último levantamento feito pela CNC (Confederação
                    Nacional do Comércio de Bens, serviços e turismo), o número
                    de famílias atingidas pelo endividamento chegou a 79,3% dos
                    lares brasileiros em setembro de 2022, em tese, são três a
                    cada 10 famílias em estado de inadimplência no país.{" "}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}>
                    Acima de tudo, a situação se torna ainda mais preocupante,
                    não somente pelo fator financeiro, mas principalmente pelos
                    efeitos psicológicos e abalos ocasionados nas pessoas e
                    famílias por conta das dívidas.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    O estudo feito, mostra que 83% dos endividados sofrem de
                    insônia por causa das dívidas e 74% têm dificuldade para se
                    concentrar. Foram mais de 5 mil pessoas ouvidas na pesquisa,
                    por todas as regiões do país. Além disso, “78% dos
                    entrevistados têm surtos de pensamentos negativos devido aos
                    débitos vencidos e 61% viveram ou vivem sensação de “crise e
                    ansiedade” ao pensar nas dívidas.”
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Autoestima e autoconfiança afetadas
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    A pesquisa revelou que os entrevistados sentem que o
                    endividamento afeta sua autoestima. A sensação de não
                    conseguir lidar adequadamente com as finanças e a
                    dependência de crédito podem gerar sentimentos de culpa,
                    vergonha e baixa autoconfiança. Isso pode levar a um ciclo
                    vicioso de comportamentos financeiros prejudiciais.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Para Valéria Meirelles, que promoveu o suporte técnico e
                    análise do estudo,{" "}
                    <Link
                      target="_blank"
                      href="https://veja.abril.com.br/saude/pesquisa-mostra-impactos-do-endividamento-na-saude-mental-do-brasileiro#:~:text=A%20quinta%20edi%C3%A7%C3%A3o%20da%20Pesquisa,t%C3%AAm%20dificuldade%20para%20se%20concentrar.">
                      “O sistema biológico é o primeiro a sentir os efeitos da
                      preocupação com as dívidas”
                    </Link>
                    . Ao mesmo tempo, explicou a psicóloga que “A ansiedade vai
                    invadindo a rotina de quem busca incansavelmente uma
                    solução, o endividado passa a viver com pensamentos voltados
                    ao futuro, não consegue mais relaxar e, consequentemente,
                    não se concentra nas suas tarefas habituais nem consegue
                    mais dormir com normalidade”.
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}>
                    Em suma, mais de 33% das pessoas, não sentem confiança
                    suficiente para tomar as rédeas sobre pagamentos de suas
                    dívidas, e nem para cuidar de suas próprias finanças,
                    delegando essa função a segundos e até terceiros. Ao mesmo
                    tempo 53% dos entrevistados, sentem medo profundo do futuro
                    e muita tristeza.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Impacto nas relações interpessoais
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    A pressão financeira e o endividamento podem gerar conflitos
                    e tensões nas relações familiares e sociais. Cerca de 31%
                    dos entrevistados afirmaram que o endividamento afeta
                    negativamente seus relacionamentos mais próximos.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    E afirmam que pararam até de frequentar reuniões familiares
                    por conta de suas situações. Além disso, 62% dos pesquisados
                    informaram que o endividamento gerou impacto negativo em
                    seus relacionamentos conjugais.
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}>
                    A preocupação constante com as dívidas pode interferir na
                    qualidade de vida e no equilíbrio emocional, afetando também
                    o convívio social.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Como a <span className="quita">Quita Boletos </span>
                    pode ajudar
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Nós surgimos como uma solução para aliviar os impactos
                    psicológicos do endividamento.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Ao escolher a Quita Boletos, você não apenas garante o
                    pagamento das suas contas, mas também abre espaço para uma
                    saúde financeira mais estável. Isso pode contribuir para
                    reduzir o estresse, a ansiedade e melhorar a autoestima.
                    Afinal, ter um parceiro confiável para cuidar das finanças é
                    uma forma efetiva de minimizar os impactos psicológicos do
                    endividamento.
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}>
                    Não deixe que o peso das dívidas afete seu bem-estar
                    emocional. Busque alternativas e comece a trilhar o caminho
                    para uma vida financeira mais equilibrada e saudável.
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}>
                    Se você está enfrentando o endividamento, acesse nosso site
                    e conheça mais sobre nossos serviços que podem ajudar a
                    reconstruir sua VIDA.
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
