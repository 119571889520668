import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";
import { Link } from "@mui/material";
import { abreSimulador } from "@/services/utils";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";

export default function EntendaComoParcelarIPTUeIPVANoCartao() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_ENTENDA_COMO_PARCELAR_SEU_IPTU_E_IPVA_NO_CARTAO_DE_CREDITO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog-parcelar-iptu-e-ipva.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                Entenda como parcelar seu IPTU e IPVA no cartão de crédito
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontStyle: "italic", fontSize: 20 }}>
                Parcelar IPTU e IPVA pode ser a melhor solução para quem não
                possui todo o valor necessário de imediato ou deseja mais
                flexibilidade no pagamento.
              </Typography>
              <Typography sx={{ fontSize: 20, mt: 2 }}>
                Neste artigo, vamos explicar como parcelar seu <b>IPTU</b> e{" "}
                <b>IPVA</b> no <u>cartão de crédito</u> utilizando a solução{" "}
                <span className="quita">Quita Boletos</span>, além de responder
                às principais dúvidas sobre esses impostos. Leia até o final
                para entender como funciona.
              </Typography>
            </Grid>

            <Grid item container spacing={2} className="sessao">
              <Grid item xs={12}>
                <Typography variant="h3">IPTU e IPVA</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20, pb: 1 }}>
                  Quando se trata de impostos relacionados a propriedades e
                  veículos, dois dos mais comuns no Brasil são o <b>IPTU</b> e o{" "}
                  <b>IPVA</b>. Entender a diferença entre eles é crucial para
                  saber como cada um impacta o seu bolso e quais são as suas
                  responsabilidades como contribuinte.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  IPTU (Imposto Predial e Territorial Urbano)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  O IPTU é um imposto municipal cobrado sobre a propriedade de
                  imóveis urbanos, como casas, apartamentos, terrenos e
                  estabelecimentos comerciais. Este imposto é pago pelos
                  proprietários desses imóveis e tem como objetivo financiar
                  serviços públicos municipais, como saúde, educação, saneamento
                  e infraestrutura urbana.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  IPVA (Imposto sobre a Propriedade de Veículos Automotores)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  O IPVA é um imposto estadual cobrado sobre a propriedade de
                  veículos automotores, como carros, motos, caminhões e ônibus.
                  Os proprietários de veículos registrados no estado são
                  responsáveis por pagar esse imposto. O IPVA arrecadado é
                  dividido entre o estado e o município onde o veículo é
                  registrado e é utilizado para financiar serviços públicos,
                  como transporte, segurança e infraestrutura rodoviária.
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Com a <span className="quita">Quita Boletos</span> você pode
                  parcelar seu <b>IPTU</b> e <b>IPVA</b> no cartão de crédito,
                  facilitando ainda mais a gestão desses pagamentos. Aqui estão
                  algumas das principais vantagens:
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ fontSize: 20 }}>
                <ol>
                  <li>
                    <b>Flexibilidade financeira</b> <br />
                    Parcelar esses impostos permite distribuir o valor total ao
                    longo de vários meses, aliviando o impacto financeiro
                    imediato. Isso é especialmente útil para quem não tem todo o
                    valor disponível à vista.
                  </li>
                  <li>
                    <b>Melhor gestão do orçamento</b> <br />
                    Com parcelas mensais, é mais fácil planejar e gerir o
                    orçamento. As despesas são distribuídas de maneira mais
                    equilibrada, evitando grandes desembolsos únicos.
                  </li>
                  <li>
                    <b>Evita multas e juros de atraso</b> <br />
                    Parcelar no cartão de crédito garante que os impostos serão
                    pagos dentro do prazo, evitando multas e juros por atraso.
                    Isso pode resultar em uma economia significativa a longo
                    prazo.
                  </li>
                </ol>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Essas vantagens tornam o <b>parcelamento de IPTU e IPVA</b> no
                  cartão de crédito uma opção atraente para muitos
                  contribuintes, facilitando o cumprimento das obrigações
                  fiscais de forma mais tranquila e organizada.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Como parcelar seu IPTU e IPVA no cartão de crédito com a{" "}
                  <span className="quita">Quita Boletos</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Com a nossa solução, você parcela o valor total em até{" "}
                  <b>12 vezes no cartão de crédito</b>, enquanto nós quitamos
                  seu débito à vista. É fácil, seguro e 100% online. Para
                  parcelar seu <b>IPTU</b> e <b>IPVA</b> no cartão de crédito,
                  siga o passo a passo:
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ fontSize: 20 }}>
                <ol>
                  <li>
                    Você pode <b>simular</b> clicando{" "}
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => abreSimulador()}>
                      AQUI{" "}
                    </Link>{" "}
                    ou em <b>SIMULAR</b>.
                  </li>
                  <li>
                    Insira o <b>valor total</b> que deseja parcelar, seus dados
                    e clique em <b>Próximo</b>.
                  </li>
                  <li>
                    Escolha a quantidade de <b>parcelas</b> desejada
                  </li>
                  <li>
                    Selecione a opção <b>IPVA/Licenciamento</b> e insira os
                    dados necessários para prosseguir.
                  </li>
                  <li>
                    Para finalizar, insira os dados do cartão de crédito e
                    clique em 'Pagar agora'.
                  </li>
                </ol>
              </Grid>
              <Grid item xs={12} sx={{ fontSize: 20 }}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  <b>Importante:</b> você pode utilizar quantos cartões de
                  crédito quiser para completar o valor da operação.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Parcele IPTU e IPVA com segurança
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Sim, nosso sistema incorpora múltiplas camadas de segurança na
                  operação, incluindo autenticação biométrica e sistemas
                  antifraude avançados. Todas as informações transmitidas são
                  criptografadas de ponta a ponta para assegurar a
                  confidencialidade da transação. Nossa plataforma emprega uma
                  variedade de mecanismos para proteger seus dados, garantindo
                  qualidade testada e aprovada em termos de segurança.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">Resumo:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Parcelar o IPTU e IPVA no cartão de crédito pode ser uma
                  solução conveniente e flexível para muitos contribuintes,
                  oferecendo vantagens como flexibilidade financeira, melhor
                  gestão do orçamento e a evitação de multas e juros por atraso.
                  Neste artigo, explicamos a diferença entre IPTU e IPVA,
                  destacando como cada imposto afeta os contribuintes. Além
                  disso, detalhamos como é possível parcelar esses impostos
                  utilizando a solução Quita Boletos, proporcionando um passo a
                  passo claro e simples para realizar o processo.
                </Typography>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Por fim, ressaltamos a importância da segurança no processo de
                  parcelamento. Agora, parcelar o IPTU e IPVA no cartão de
                  crédito torna-se não apenas mais flexível, mas também seguro e
                  eficaz, proporcionando aos contribuintes uma forma mais
                  tranquila e organizada de cumprir suas obrigações fiscais.
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular></BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
