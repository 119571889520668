import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function GuiaParaManterAsFinancasEmOrdem() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_GUIA_PARA_MANTER_SUAS_FINANÇAS_EM_ORDER",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid container justifyContent="center">
          <Grid
            xs={12}
            sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={3} maxWidth="sm" sx={{ pt: 3, pb: 3 }}>
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog-img"
                  src="/site/blog/blog-guia-para-manter-as-financas-em-ordem.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Mantenha suas finanças em ordem com estas dicas simples
                </Typography>
              </Grid>

              <Grid container spacing={1} className="sessao">
                <Grid item xs={12}>
                  <Typography variant="h3" className="titulo">
                    Manter as finanças em ordem é essencial para garantir
                    estabilidade e segurança financeira. Neste artigo, vamos
                    compartilhar algumas dicas simples que podem te ajudar a
                    manter suas finanças em dia e evitar problemas no futuro.
                    Acompanhe e coloque em prática essas dicas para ter mais
                    controle sobre o seu dinheiro.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Saiba como manter suas finanças sob controle e enfrentar os
                    desafios que podem surgir ao longo do caminho.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} className="sessao tutorial">
                <Grid item xs={12}>
                  <ol className="lista">
                    <li>
                      <b>
                        Conte com a <span className="quita">Quita Boletos</span>{" "}
                        na hora do aperto
                      </b>{" "}
                      <br />
                      Oferecemos opções de parcelamento em até 12 vezes e
                      aceitação em vários cartões de crédito para facilitar o
                      pagamento, à vista, de seus boletos ou PIX. Assim, você
                      pode manter suas finanças sob controle mesmo em situações
                      de aperto.
                    </li>
                    <li>
                      <b>Mantenha seu nome limpo</b> <br />
                      Nada é mais importante para a sua saúde financeira do que
                      manter seu nome limpo. Uma dívida pendente pode causar
                      estragos em suas finanças e afetar sua credibilidade no
                      mercado. Com nossas opções de parcelamento acessíveis,
                      ajudamos você a quitar suas dívidas pendentes, permitindo
                      que você recupere sua tranquilidade financeira e proteja
                      seu nome.
                    </li>
                    <li>
                      <b>Organize suas finanças</b> <br />A gestão financeira
                      eficaz é essencial para enfrentar os altos e baixos da
                      vida. Se você está enfrentando um mês difícil e suas
                      despesas parecem superar sua renda, não entre em pânico.
                      Estamos aqui para ajudá-lo a reorganizar suas finanças e a
                      encontrar soluções viáveis. Revise seu planejamento
                      financeiro, renegociando parcelas ou buscando alternativas
                      de receita, estamos comprometidos em ajudá-lo a superar os
                      desafios financeiros e a retomar o controle da sua vida
                      econômica.
                    </li>
                    <li>
                      <b>Tire os planos do papel</b> <br /> Todos nós temos
                      sonhos e projetos que gostaríamos de realizar, mas muitas
                      vezes o obstáculo financeiro parece intransponível. Não
                      deixe que a falta de recursos financeiros impeça você de
                      alcançar seus objetivos. Com nossas opções de parcelamento
                      flexíveis, tornamos mais fácil transformar seus planos em
                      realidade. Seja para iniciar um novo negócio, fazer uma
                      viagem dos sonhos ou investir em seu desenvolvimento
                      pessoal, estamos aqui para apoiá-lo em cada etapa do
                      caminho.
                    </li>
                  </ol>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Ter suas finanças em ordem é essencial para alcançar a
                    estabilidade e a segurança financeira. Não importa quais
                    sejam seus desafios ou objetivos financeiros, estamos aqui
                    para ajudá-lo a enfrentá-los e a transformar seus sonhos em
                    realidade. Conte conosco na hora do aperto, mantenha seu
                    nome limpo, organize suas finanças e tire os planos do
                    papel.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Juntos, vamos construir um futuro financeiro sólido e
                    próspero.
                  </Typography>
                </Grid>

                <Grid item xs={12} align="center">
                  <BotaoSimular>
                    CLIQUE AQUI E REALIZE UMA SIMULAÇÃO
                  </BotaoSimular>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
