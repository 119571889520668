import { Helmet } from "react-helmet";
import { Box, Paper } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CookieBanner from "@/components/CookieBanner";
import BotaoSimular from "@/components/BotaoSimular";
import SecaoCadastro from "./secao-cadastro";
import SecaoOpiniaoClientes from "@/pages/commom/opiniao-clientes";
import "./index.scss";

export default function Inicio() {
  return (
    <>
      <Container id="inicio" component="main" className="secao-site secao-1">
        <Helmet>
          <title>Quita Boletos</title>
          <link rel="canonical" href="https://quitaboletos.com.br" />
          <meta
            name="description"
            content="Simule o parcelamento de seu boleto ou PIX em até 12x no cartão de crédito."
          />
        </Helmet>

        <CookieBanner />

        <Grid container sx={{ p: 3, m: "auto" }} maxWidth="lg">
          <Grid item xs={12} sx={{ p: 1 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" className="texto-parcele-em-x-vezes">
                Parcele PIX ou boletos em até 12x no cartão de crédito.
              </Typography>
              <Typography className="texto-secundario" sx={{ pt: 2 }}>
                Com parcelamento simples e flexível, viver o presente fica
                fácil.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} className="botao-container">
            <BotaoSimular className="botao-simular-principal">
              SIMULAR AGORA
            </BotaoSimular>
          </Grid>
        </Grid>
      </Container>

      <Container className="secao-site secao-2" component="section">
        <Grid
          container
          sx={{
            width: { xs: "100%", md: "40%" },
            height: "100%",
            position: { md: "absolute" },
          }}>
          <Grid item xs={12} className="container-texto">
            <Typography className="texto-secao">
              <span className="texto-destaque">
                Flexibilidade para parcelar PIX em até 12x:
              </span>{" "}
              Você merece planejar e gerenciar suas finanças com facilidade e
              sem comprometer seu orçamento de uma só vez.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mx: { xs: "2rem", sm: 0 }, mt: { sm: 3 } }}>
            <BotaoSimular className="botao-simular-amarelo">
              PARCELE SEU PIX COM FACILIDADE
            </BotaoSimular>
          </Grid>
        </Grid>
      </Container>

      <Container className="secao-site secao-conte-conosco" component="section">
        <Grid
          container
          spacing={3}
          sx={{ m: { md: "auto" } }}
          maxWidth="lg"
          justifyContent="center">
          <Grid item xs={12}>
            <Typography className="titulo">
              As contas do mês pesaram? <br />
              Com a Quita, fica mais leve.
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sx={{ margin: { xs: "1rem", md: 0 } }}>
            <Grid className="card" sx={{ backgroundColor: "#104501" }}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "24px", color: "#D8FF57" }}>
                Liberdade para realizar PIX mesmo sem saldo na conta!
              </Typography>

              <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                Flexibilidade para parcelar seu próximo PIX em até 12x: você
                merece planejar e gerenciar suas finanças com facilidade e sem
                comprometer o orçamento de uma vez só.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3} sx={{ margin: { xs: "1rem", md: 0 } }}>
            <Grid className="card" sx={{ backgroundColor: "#ffd12f" }}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "24px", color: "#104501" }}>
                Manter o nome limpo nunca foi tão fácil!
              </Typography>

              <Typography sx={{ fontSize: "18px" }}>
                Sabe aquela dívida que é uma dor de cabeça? Nossa solução é uma
                mão na roda para manter o seu nome limpo: parcele como e em
                quantas vezes preferir.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3} sx={{ margin: { xs: "1rem", md: 0 } }}>
            <Grid className="card" sx={{ backgroundColor: "#d8ff57" }}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "24px", color: "#104501" }}>
                Uma vida plena também envolve organizar as finanças!
              </Typography>

              <Typography sx={{ fontSize: "18px" }}>
                Imprevistos acontecem e podem abalar até os orçamentos mais
                cuidadosos. Oferecemos uma solução que vai além do parcelamento
                de contas para proporcionar o alívio financeiro que você
                precisa.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3} sx={{ margin: { xs: "1rem", md: 0 } }}>
            <Grid className="card" sx={{ backgroundColor: "#ffb3d2" }}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "24px", color: "#104501" }}>
                Concentre a sua energia no que realmente importa!
              </Typography>

              <Typography sx={{ fontSize: "18px" }}>
                Algumas ocasiões merecem que você esteja 100% presente, sem
                nenhuma preocupação com contas ou boletos a vencer. Nossa
                solução digital foi feita para isso: proporcionar alívio e
                liberdade.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className="secao-site secao-3" component="section">
        <Grid container justifyContent="center" sx={{ pb: 3 }}>
          <Grid item xs={12} align="center">
            <Typography variant="h3" className="titulo">
              Como podemos ajudar você?
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mr: { xs: 2, md: 0 },
              }}>
              <Grid
                sx={{
                  top: "20px",
                  left: "40px",
                  width: "70px",
                  position: "relative",
                }}>
                <Paper
                  elevation={3}
                  sx={{
                    backgroundColor: "#FFD12F",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <Grid sx={{ p: 1 }}>
                    <img src="/site/contas-icon.svg" alt="icone contas" />
                  </Grid>
                </Paper>
              </Grid>

              <Paper elevation={3} className="card-beneficios">
                <Typography className="text">
                  <span className="titulo-beneficio">Parcele suas contas:</span>
                  <br />
                  Água, luz, internet, aluguel e muito mais.
                </Typography>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mr: { xs: 2, md: 0 },
              }}>
              <Grid
                sx={{
                  top: "20px",
                  left: "40px",
                  width: "70px",
                  position: "relative",
                }}>
                <Paper
                  elevation={3}
                  sx={{
                    backgroundColor: "#FFD12F",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <Grid sx={{ p: 1 }}>
                    <img src="/site/pix-icon.svg" alt="icone pix" />
                  </Grid>
                </Paper>
              </Grid>

              <Paper elevation={3} className="card-beneficios">
                <Typography className="text">
                  <span className="titulo-beneficio">Parcele pix:</span>
                  <br /> Sem saldo na conta? A Quita ajuda a realizar os
                  pagamentos pendentes.
                </Typography>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mr: { xs: 2, md: 0 },
              }}>
              <Grid
                sx={{
                  top: "20px",
                  left: "40px",
                  width: "70px",
                  position: "relative",
                }}>
                <Paper
                  elevation={3}
                  sx={{
                    backgroundColor: "#FFD12F",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <Grid sx={{ p: 1 }}>
                    <img
                      src="/site/calendar_clock.svg"
                      alt="Icone contas atraso"
                    />
                  </Grid>
                </Paper>
              </Grid>

              <Paper elevation={3} className="card-beneficios">
                <Typography className="text">
                  <span className="titulo-beneficio">
                    Diga adeus aos atrasos:
                  </span>
                  <br /> Mantenha seu nome limpo e todas as contas em dia.
                </Typography>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mr: { xs: 2, md: 0 },
              }}>
              <Grid
                sx={{
                  top: "20px",
                  left: "40px",
                  width: "70px",
                  position: "relative",
                }}>
                <Paper
                  elevation={3}
                  sx={{
                    backgroundColor: "#FFD12F",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <Grid sx={{ p: 1 }}>
                    <img src="/site/alarm_add.svg" alt="Icone alarme" />
                  </Grid>
                </Paper>
              </Grid>

              <Paper elevation={3} className="card-beneficios">
                <Typography className="text">
                  <span className="titulo-beneficio">
                    Ganhe mais tempo para pagar:
                  </span>
                  <br /> A Quita liquida suas contas à vista e você parcela em
                  até 12x no cartão de crédito.
                </Typography>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mr: { xs: 2, md: 0 },
              }}>
              <Grid
                sx={{
                  top: "20px",
                  left: "40px",
                  width: "70px",
                  position: "relative",
                }}>
                <Paper
                  elevation={3}
                  sx={{
                    backgroundColor: "#FFD12F",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <Grid sx={{ p: 1 }}>
                    <img
                      src="/site/account_balance_wallet.svg"
                      alt="Icone carteira"
                    />
                  </Grid>
                </Paper>
              </Grid>

              <Paper elevation={3} className="card-beneficios">
                <Typography className="text">
                  <span className="titulo-beneficio">
                    Parcelas que cabem no seu bolso:
                  </span>
                  <br /> Não tem todo o dinheiro de uma vez? Com a Quita, você
                  parcela no crédito e escolhe o parcelamento que se encaixa no
                  seu orçamento.
                </Typography>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} align="center">
            <BotaoSimular className="botao-simular">SIMULAR AGORA</BotaoSimular>
          </Grid>
        </Grid>
      </Container>

      <Container
        component="section"
        className="secao-site secao-4"
        sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "32px", color: "#104501" }}>
                  Nosso compromisso é <strong>transformar sua rotina</strong>,
                  por meio de soluções flexíveis, práticas e acessíveis que
                  aliviam o peso das suas obrigações financeiras.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ my: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mr: { xs: 2, md: 0 },
              }}>
              <Grid className="card-estatistica">
                <Paper
                  elevation={3}
                  className="card-number"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: { xs: "50px", md: "128px" },
                  }}>
                  8
                </Paper>
              </Grid>

              <Paper elevation={3} className="card-avaliacao">
                <Grid container sx={{ height: "100%" }}>
                  <Typography
                    className="text"
                    sx={{ fontSize: { xs: "20px", md: "24px" } }}>
                    em cada 10 clientes avaliam nossa solução e atendimento com
                    nota 10!
                  </Typography>
                </Grid>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mr: { xs: 2, md: 0 },
              }}>
              <Grid className="card-estatistica">
                <Paper
                  elevation={3}
                  className="card-number"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: { xs: "50px", md: "128px" },
                  }}>
                  7
                </Paper>
              </Grid>

              <Paper elevation={3} className="card-avaliacao">
                <Grid container sx={{ height: "100%" }}>
                  <Typography
                    className="text"
                    sx={{ fontSize: { xs: "20px", md: "24px" } }}>
                    em cada 10 clientes voltam para parcelar com a gente
                    novamente!
                  </Typography>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        className="secao-site secao-qualidade-testada"
        component="section">
        <Grid container justifyContent="center" sx={{ mb: 2 }}>
          <Grid item xs={12} align="center">
            <Typography variant="h3" className="titulo">
              Qualidade comprovada e aprovada!
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ m: { sm: "auto" }, px: { xs: 2, sm: 0 }, py: 2 }}
          maxWidth="sm"
          className="item-programas"
          justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper elevation={3} className="card-programas">
              <Typography className="imagem">
                <img src="/site/miralabs.png" alt="logo miralabs"></img>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} className="card-programas">
              <Typography className="imagem">
                <img
                  src="/site/caldeira.png"
                  alt="logo instituto caldeira"></img>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} className="card-programas">
              <Typography className="imagem">
                <img src="/site/sebrae_logo.png" alt="logo sebrae"></img>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} className="card-programas">
              <Typography className="imagem">
                <img src="/site/wow_logo.png" alt="logo wow aceleradora"></img>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container className="secao-site secao-5" component="section">
        <Grid
          container
          maxWidth="md"
          justifyContent="center"
          sx={{
            p: 6,
            display: "flex",
            justifyContent: "center",
            border: "4px solid #D8FF57",
            borderRadius: "2rem",
          }}>
          <Grid item xs={12} align="center">
            <Typography sx={{ fontSize: "18px", mb: 2 }}>
              MAIS CRÉDITOS AO SEU FUTURO SEM SURPRESAS OU MISTÉRIOS.
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1.5rem", sm: "3rem" },
                color: "#104501",
                fontWeight: "bold",
              }}>
              Nosso propósito é equilibrar as finanças com transparência e
              proteção de dados. E sem comprometer a sua liberdade de curtir o
              agora.
            </Typography>
          </Grid>
          <Grid item xs={12} align="center" sx={{ mt: 2 }}>
            <img
              className="logo-reclame-aqui"
              src="/site/logo_reclame_aqui.png"
              alt="logo reclame aqui"
            />
          </Grid>
        </Grid>
      </Container>

      <SecaoOpiniaoClientes />

      <SecaoCadastro />
    </>
  );
}
