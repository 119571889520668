import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";
import "./index.scss";

export default function MenosDividasMaisEsperancaFinanceira() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_BRASIL_2023_MENOS_DIVIDAS_MAIS_ESPERANCA_FINANCEIRA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post" justifyContent="center">
        <Grid container justifyContent="center">
          <Grid
            className="logo-blog"
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="sm"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog-img"
                  src="/site/blog/blog-menos-dividas-mais-esperanca.png"
                  alt="logo-blog"
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Brasil 2023: Menos dívidas, mais esperança financeira
                </Typography>
              </Grid>

              <Grid container className="sessao intro">
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}
                    gutterBottom>
                    2023 está chegando ao fim; porém, uma pesquisa realizada no
                    começo do ano, pelo Observatório Febraban, revelou um
                    otimismo notável entre os brasileiros. Mais da metade
                    acreditava que estariam menos endividados do que em 2022.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20, textAlign: "left !important", my: 2 }}
                    className="descricao"
                    component={"article"}
                    gutterBottom>
                    Agora, vamos a uma análise profunda dos fatos.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}
                    gutterBottom>
                    Neste artigo, iremos explorar o desejo de progresso da
                    população referente às suas dívidas e traremos dicas de como
                    é possível, ainda este ano, livrar-se de muitos boletos
                    incômodos.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="sessao">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    O Otimismo financeiro dos brasileiros
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    De acordo com a pesquisa, 56% dos entrevistados acreditam
                    que suas dívidas estarão sob controle em 2023. Este otimismo
                    se estende por diversas faixas etárias, mas é mais evidente
                    entre os jovens de 18 a 24 anos, onde impressionantes 64%
                    acreditam que suas finanças melhorarão.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="sessao">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    Por que as finanças estão no topo da lista?
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    A pesquisa também mostrou que, entre os diversos aspectos da
                    vida pessoal que podem melhorar em 2023, as finanças lideram
                    o ranking.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Surpreendentemente, 36% dos entrevistados escolheram as
                    finanças como a área mais promissora para este ano.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="sessao">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    Prioridades em ascensão
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    A saúde física ficou em segundo lugar, com 28% dos
                    entrevistados que acreditaram que este ano sua saúde física
                    estaria melhor. A saúde mental também está em destaque,
                    sinalizando que o bem-estar pessoal é uma prioridade
                    crescente. O trabalho ou emprego ocupou a quarta posição,
                    com 23% das menções.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="sessao">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    Outras áreas de foco
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Além disso, a pesquisa indicou que as relações interpessoais
                    (16%), lazer e entretenimento (12%), e moradia (10%) também
                    são áreas em que os brasileiros esperam ver melhorias ainda
                    em 2023.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="sessao">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    Transformando expectativas em realidade com{" "}
                    <span className="quita">Quita Boletos</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Aqui na <span className="quita">Quita Boletos</span>,
                    acreditamos que a chave para um futuro financeiro mais
                    promissor em 2023 é o planejamento e a gestão inteligente
                    das dívidas. Entramos em cena para ajudar você a transformar
                    suas metas em realidade, afinal o ano ainda não acabou, e
                    com as facilidades que oferecemos, você pode sim realizar o
                    sonho de findar 2023 menos endividado.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Nós oferecemos soluções de parcelamento flexíveis e
                    vantajosas que permitem que você recupere o controle das
                    suas finanças. Nossos serviços abrangem desde a renegociação
                    de dívidas até a facilidade do pagamento via Pix,
                    proporcionando opções sob medida para suas necessidades.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Com a <span className="quita">Quita</span>, a sua dívida de
                    2022, 2023 e de outros anos cabe em qualquer cartão de
                    crédito e pode ser parcelada em até 12x.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Prepare-se para um novo ano com menos dívidas e mais
                    controle sobre suas finanças. Vem com a gente, simule agora
                    mesmo clicando em <span className="quita">SIMULAR</span> ou{" "}
                    <span className="quita">no botão abaixo</span> e veja suas
                    perspectivas financeiras prestes a se tornar uma realidade.
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
