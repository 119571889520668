import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CardPosts from "./CardPosts";
import Newsletter from "./newsletter";
import "./index.scss";

export default function Blog() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-blog secao-posts" component={"main"}>
        <Helmet>
          <title>Blog</title>
          <link rel="canonical" href="https://quitaboletos.com.br/blog" />
          <meta
            name="description"
            content="Confira nossas postagens e receba dicas incríveis para organizar seu orçamento."
          />
        </Helmet>

        <Grid
          container
          spacing={2}
          sx={{ p: { xs: 0, md: 3 } }}
          justifyContent="center">
          <Grid item xs={12} align="center">
            <Grid item xs={12} sm={10} md={6}>
              <Typography
                sx={{
                  pb: 2,
                  my: 2,
                  fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                  fontWeight: "bold",
                  lineHeight: 1.2,
                }}>
                 Fique por dentro das últimas notícias sobre finanças e
                investimentos!
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <CardPosts />
        </Grid>

        <Newsletter />
      </Container>
    </>
  );
}
