import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import "./index.scss";

export default function SecaoOpiniaoClientes() {
  return (
    <Container
      className="secao-site secao-opiniao-clientes"
      component="section">
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography
            color="primary"
            sx={{
              fontWeight: "bold",
              pt: { xs: 0, md: 5 },
              display: "flex",
              justifyContent: "center",
              fontSize: { xs: "1.8rem", sm: "3rem" },
              textAlign: "center",
            }}>
            O que os nossos clientes dizem sobre nós
          </Typography>
          <Typography
            sx={{
              pt: 4,
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}>
            Dá uma olhada no que nossos clientes dizem sobre nós!
          </Typography>
        </Grid>
      </Grid>

      <Grid container className="cards-comentarios">
        <Swiper
          spaceBetween={50}
          navigation={isMobile ? false : true}
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            600: {
              slidesPerView: 1,
            },
            1000: {
              slidesPerView: 3,
            },
          }}>
          <SwiperSlide>
            <Card className="card-comentario">
              <CardContent>
                <Typography className="texto">
                  Foi uma ótima experiência! Pela agilidade e competência, com
                  certeza, eu recomendaria os serviços.
                </Typography>
              </CardContent>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="card-comentario">
              <CardContent>
                <Typography className="texto">
                  Recomendo muito! É confiável, e o atendimento é excelente.
                  Sempre que precisei de suporte, minhas dúvidas foram sanadas e
                  resolvidas rapidamente. Recomendo também pela facilidade no
                  pagamento.
                </Typography>
              </CardContent>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="card-comentario">
              <CardContent>
                <Typography className="texto">
                  O atendimento é rápido e claro. 100% das vezes meus pedidos
                  foram resolvidos.
                </Typography>
              </CardContent>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="card-comentario">
              <CardContent>
                <Typography className="texto">
                  Achei a experiência ótima! Tudo foi rápido e objetivo,
                  cumprindo perfeitamente a função a que o serviço se propõe.
                </Typography>
              </CardContent>
            </Card>
          </SwiperSlide>
        </Swiper>
      </Grid>
    </Container>
  );
}
