import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Header from "../SiteLayout/Header";

// ==============================|| MINIMAL LAYOUT ||============================== //

export default function LandingPagesLayout() {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1 }}
        alignItems="center">
        <Toolbar></Toolbar>
        <Outlet />
      </Box>
    </Box>
  );
}
