import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";
import "./index.scss";

export default function ParcelarMeusBoletos() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_COMO_PARCELAR_MEUS_BOLETOS",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post" justifyContent="center">
        <Grid container justifyContent="center">
          <Grid
            className="logo-blog"
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="sm"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog-img"
                  src="/site/logo-como-parcelar-meus-boletos.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Como parcelar meus boletos?
                </Typography>
              </Grid>

              <Grid container className="sessao intro">
                <Grid item className="left-item" xs={12} sm={6}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    component={"article"}>
                    Quando as contas começam a se acumular e o orçamento aperta,
                    é natural procurar maneiras de facilitar o pagamento. Uma
                    opção que pode ajudar nessa situação é o parcelamento de
                    boletos. Neste blog, vamos explorar como é possível parcelar
                    seus boletos utilizando o seu cartão de crédito.
                  </Typography>
                </Grid>

                <Grid
                  item
                  className="rigth-item"
                  xs={12}
                  sm={6}
                  sx={{ pt: { xs: 3, md: 0 } }}>
                  <img
                    className="logo-blog-img cartao"
                    src="/site/logo-cartao.png"
                    alt=""
                  />
                </Grid>
              </Grid>

              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Os benefícios do parcelamento de boletos
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    O parcelamento de boletos pode ser uma solução eficaz para
                    lidar com despesas imprevistas ou contas que não se encaixam
                    no orçamento mensal. Aqui estão alguns benefícios que você
                    pode obter ao optar pelo parcelamento:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ol className="lista">
                    <li>
                      <span className="quita">Flexibilidade de pagamento:</span>{" "}
                      Ao parcelar seus boletos, você tem a possibilidade de
                      dividir o valor total em parcelas mensais. Isso
                      proporciona mais flexibilidade no gerenciamento das suas
                      finanças, permitindo que você distribua o pagamento ao
                      longo do tempo.
                    </li>
                    <li>
                      <span className="quita">Alívio financeiro:</span> Parcelar
                      boletos pode ajudar a reduzir a pressão sobre o orçamento
                      mensal. Ao dividir o valor em parcelas menores, você evita
                      que uma única despesa impacte significativamente suas
                      finanças, facilitando o equilíbrio entre os gastos e a
                      renda.
                    </li>
                    <li>
                      <span className="quita">Evita multas:</span> Muitas vezes,
                      quando um boleto não é pago até a data de vencimento,
                      multas são adicionadas ao valor original. Ao optar pelo
                      parcelamento, você evita multas que são acumuladas por dia
                      e paga apenas o valor fixo das taxas de acordo com o
                      número de parcelas.
                    </li>
                    <li>
                      <span className="quita">Maior controle financeiro:</span>{" "}
                      Parcelar boletos permite um melhor planejamento
                      financeiro, pois você sabe exatamente quais são as
                      parcelas a serem pagas nos próximos meses. Isso facilita o
                      acompanhamento das despesas e o ajuste do orçamento para
                      acomodar os pagamentos parcelados.
                    </li>
                  </ol>
                </Grid>
              </Grid>

              <Grid container className="sessao tutorial">
                <Grid item xs={12}>
                  <Typography variant="h1">
                    Como parcelar seus boletos com a{" "}
                    <span className="quita">Quita Boletos</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ol className="lista">
                    <li>
                      Acesse o site da{" "}
                      <span className="quita">Quita Boletos;</span>
                    </li>
                    <li>
                      Clique no botão <strong>"simulação"</strong>, no menu
                      superior, adicione o valor que deseja e escolha em quantas
                      vezes gostaria de parcelar as suas contas;
                    </li>
                    <li>
                      Adicione a sua forma de pagamento, insira um cartão de
                      crédito com limite para realizar o seu parcelamento;
                    </li>
                    <li>
                      Aguarde a nossa equipe entrar em contato para realizar a
                      verificação de segurança e finalizar a sua solicitação
                    </li>
                  </ol>
                </Grid>
                <Grid item xs={12} align="center">
                  <BotaoSimular>
                    CLIQUE AQUI E REALIZE UMA SIMULAÇÃO
                  </BotaoSimular>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
