import { Button } from "@mui/material";
import { direcionaParaApp } from "@/services/utils";

export default function BotaoSimular(props) {
  return (
    <Button
      variant="contained"
      onClick={() => {
        direcionaParaApp("/app/simulacao");
      }}
      {...props}>
      {props.children || "CLIQUE AQUI E REALIZE UMA SIMULAÇÃO"}
    </Button>
  );
}
