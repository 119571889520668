import SiteLayout from "@/layout/SiteLayout";
import Parceiros from "@/pages/site/Parceiros";
import Inicio from "@/pages/site/Inicio";
import ComoUsar from "@/pages/site/ComoUsar";
import TermosUso from "@/pages/site/TermosUso";
import Perguntas from "@/pages/site/Perguntas";
import SobreNos from "@/pages/site/SobreNos";

const SiteRoutes = {
  path: "/",
  element: <SiteLayout />,
  children: [
    {
      path: "/",
      element: <Inicio />,
    },
    {
      path: "/parceiros",
      element: <Parceiros />,
    },
    {
      path: "/perguntas",
      element: <Perguntas />,
    },
    {
      path: "/sobre-nos",
      element: <SobreNos />,
    },
    {
      path: "/termos-de-uso",
      element: <TermosUso />,
    },
    {
      path: "/como-funciona",
      element: <ComoUsar />,
    },
    {
      path: "*",
      element: <Inicio />,
    },
  ],
};

export default SiteRoutes;
