import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSpinner: false,
};

const spinner = createSlice({
  name: "spinner",
  initialState,
  reducers: {
    activeSpinner(state) {
      state.showSpinner = true;
    },
    cancelSpinner(state) {
      state.showSpinner = false;
    },
  },
});

export default spinner.reducer;
export const { activeSpinner, cancelSpinner } = spinner.actions;
