import { Helmet } from "react-helmet";
import { direcionaParaApp } from "@/services/utils";
import { List, ListItem } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./index.scss";

const Parceiro = () => {
  return (
    <>
      <Helmet>
        <title>Parceiros</title>
        <link rel="canonical" href="https://quitaboletos.com.br/parceiros" />
        <meta
          name="description"
          content="Seja um parceiro Quita Boletos. Ofereça opções de parcelamento para seus clientes e ganhe comissionamento!"
        />
      </Helmet>
      <Container component="main" className="pagina-parceiros logo">
        <img src="/site/logo_parceiros.png" alt=""></img>
        <div className="logo-text">
          <Typography className="increase-gains">
            Aumente seus ganhos sendo um <strong>parceiro QUITA!</strong>
          </Typography>
          <Button
            className="become-partner"
            onClick={() => {
              direcionaParaApp("/parceiro/cadastro");
            }}>
            Quero ser um parceiro
          </Button>
        </div>
      </Container>

      <Container component="main" className="pagina-parceiros perks">
        <div className="perks-left">
          <strong>VANTAGENS DE SER UM PARCEIRO</strong>
          <List className="perks-list">
            <ListItem>Comissionamento atrativo;</ListItem>
            <ListItem>Treinamento e suporte exclusivo;</ListItem>
            <ListItem>
              Portal online descomplicado, integração via API e WhiteLabel;
            </ListItem>
            <ListItem>Campanhas de incentivo para equipes de vendas;</ListItem>
            <ListItem>Sistema e Setup totalmente gratuito;</ListItem>
          </List>
        </div>
        <div className="perks-right">
          <img src="/site/logo_celular.png" alt=""></img>
        </div>
      </Container>
      <Container component="main" className="pagina-parceiros numbers">
        <div>
          <img src="/site/Icone_1.png" alt=""></img>
          <Typography className="spotlight">400 MIL</Typography>
          <Typography>CLIENTES ATENDIDOS</Typography>
        </div>
        <div>
          <img src="/site/Icone_3.png" alt="" />
          <Typography className="spotlight">35 MILHÕES</Typography>
          <Typography>DE DÉBITOS PARCELADOS</Typography>
        </div>
        <div>
          <img src="/site/Icone_4.png" alt="" />
          <Typography className="spotlight">400</Typography>
          <Typography>PARCEIROS</Typography>
        </div>
      </Container>
      <Container component="main" className="pagina-parceiros partner-type">
        <Typography className="partner-title">
          <strong>MODELOS DE PARCERIA</strong>
        </Typography>
        <Typography className="partner-description">
          Escolha o modelo que mais se encaixa na necessidade da sua empresa,
          podendo ser{" "}
          <strong className="underlined">parceiro comissionado</strong> ou para{" "}
          <strong className="underlined">parcelamento de débitos</strong>.
        </Typography>
        <div>
          <div className="partner-container">
            <Typography className="partner1-title">
              Parceiro comissionado
            </Typography>
            <Typography className="partner-description">
              Você oferece nossas soluções aos seus clientes utilizando nossa
              estrutura completa e recebe comissionamento por cada operação
              finalizada, tendo suporte do nosso time comercial e de marketing.
            </Typography>
          </div>
          <div className="partner-container">
            <Typography className="partner2-title">
              Parcelamento de débitos
            </Typography>
            <Typography className="partner-description">
              A sua empresa pode usar as nossas soluções para parcelamento de
              seus próprios debitos (boletos de fornecedores, empréstimos entre
              outros) utilizando o proprio cartão corporativo.
            </Typography>
          </div>
        </div>
        <Button
          onClick={() => {
            direcionaParaApp("/parceiro/cadastro");
          }}
          className="become-partner">
          Quero ser um parceiro
        </Button>
      </Container>
    </>
  );
};

export default Parceiro;
