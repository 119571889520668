import { useNavigate } from "react-router";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { isMobile } from "react-device-detect";
import blogPosts from "../blogPosts";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import "./index.scss";

export default function CardPosts() {
  const navigation = useNavigate();

  return (
    <>
      <Swiper
        spaceBetween={50}
        navigation={isMobile ? false : true}
        modules={[Navigation, Pagination]}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        breakpoints={{
          500: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 4,
          },
        }}>
        {blogPosts.map((post) => (
          <SwiperSlide>
            <Card key={post.url} className="card-post">
              <CardMedia
                component="img"
                image={post.image}
                title={post.titleImg}
                sx={{ height: "auto" }}
                onClick={() => {
                  navigation(`${post.url}`);
                }}
              />

              <CardContent onClick={() => navigation(`${post.url}`)}>
                <Typography
                  sx={{
                    fontSize: { xs: "1rem", md: "1.25rem", fontWeight: "bold" },
                  }}>
                  {post.title}
                </Typography>
                <Typography>{post.description}</Typography>
              </CardContent>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
