import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Header from "./Header";
import Rodape from "@/components/rodape";
import Spinner from "@/components/Spinner";
import { useSelector } from "react-redux";

// ==============================|| MINIMAL LAYOUT ||============================== //

const SiteLayout = () => {
  const { spinner } = useSelector((state) => state);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header></Header>
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1 }}
        alignItems="center">
        <Toolbar></Toolbar>
        <Outlet />

        <Rodape />
      </Box>

      <Spinner {...{ showSpinner: spinner.showSpinner }} />
    </Box>
  );
};

export default SiteLayout;
