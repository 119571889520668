import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function MasAfinalComoFunciona() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_MAS_AFINAL_COMO_FUNCIONA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid container justifyContent="center">
          <Grid
            xs={12}
            sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={3} maxWidth="sm" sx={{ pt: 3, pb: 3 }}>
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog"
                  src="/site/blog/blog-mas-afinal-como-funciona.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Mas afinal, como funciona?
                </Typography>
              </Grid>

              <Grid container spacing={1} className="sessao">
                <Grid item xs={12}>
                  <Typography variant="h3" className="titulo">
                    Parcele seus débitos com segurança: Como funciona o
                    parcelamento de PIX ou boleto da{" "}
                    <span className="quita">Quita Boletos</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Você está cansado de lidar com dívidas que parecem nunca
                    acabar? Sonha em alcançar uma vida financeira estável e
                    livre de preocupações? Se a resposta é sim, você está no
                    lugar certo!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Na <span className="quita">Quita Boletos</span>, entendemos
                    as dificuldades da inadimplência. É por isso que estamos
                    aqui para oferecer uma solução rápida, fácil e eficaz para
                    ajudar você a se livrar das dívidas de uma vez por todas.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} className="sessao tutorial">
                <Grid item xs={12}>
                  <Typography
                    variant="p"
                    sx={{
                      textAlign: "center",
                      fontSize: "1.6rem",
                      fontWeight: "bold",
                    }}>
                    Passo a passo para parcelar seus débitos com a{" "}
                    <span className="quita">Quita Boletos</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ol className="lista">
                    <li>
                      <span className="quita">Simule:</span> Clique{" "}
                      <b>SIMULAR</b> ou no botão ao final do artigo, informe o
                      valor da simulação, seu nome completo, e-mail, telefone de
                      contato e avance para a próxima etapa.
                    </li>
                    <li>
                      <span className="quita">
                        Informe os itens para pagamento:
                      </span>{" "}
                      Selecione quais débitos que deseja realizar o parcelamento
                      e informe os dados correspondentes.
                    </li>
                    <li>
                      <span className="quita">Informe seus Dados:</span> Para
                      prosseguir com a contratação, precisamos de mais alguns
                      dados pessoais como CPF, endereço, etc. Após informar os
                      dados, avance para o pagamento.
                    </li>
                    <li>
                      <span className="quita">Pagamento:</span> Pronto, agora é
                      só informar os dados do seu cartão de crédito e confirmar
                      a contratação! Fácil né?!
                    </li>
                  </ol>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Qualquer dúvida, entre em contato com o nosso time de
                    atendimento através do WhatsApp: <b>(55)99904-2233</b>.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Não espere mais para começar sua jornada rumo à liberdade
                    financeira! Junte-se a nós e comece hoje mesmo!
                  </Typography>
                </Grid>

                <Grid item xs={12} align="center">
                  <BotaoSimular>
                    CLIQUE AQUI E REALIZE UMA SIMULAÇÃO
                  </BotaoSimular>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
