import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function NossoFuturoComecaAgora() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_NOSSO_FUTURO_COMECA_AGORA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post" justifyContent="center">
        <Grid container justifyContent="center">
          <Grid
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="md"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog-img"
                  src="/site/blog/blog-nosso-futoro-comeca-agora.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid item xs={12} sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  O nosso futuro começa agora!
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{ mt: 2 }}>
                    Estamos de cara nova e ainda mais focados em trazer
                    otimizações e soluções financeiras que ajudem os brasileiros
                    a viver o presente e ter uma vida financeira mais tranquila.
                    Com novas cores, novos elementos, um site reformulado e uma
                    jornada muito mais descomplicada, queremos oferecer uma
                    experiência ainda mais completa e segura aos nossos
                    clientes.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    Segurança em primeiro lugar
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    O principal foco da nova comunicação da{" "}
                    <span className="quita">Quita</span> é a segurança. O
                    sistema da <span className="quita">Quita</span> foi
                    desenvolvido com múltiplas etapas de segurança, garantindo
                    que os dados e as transações dos usuários estejam sempre
                    protegidos.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Além disso, a nova automação via WhatsApp notifica o usuário
                    em cada etapa do pagamento, informando-o sobre como proceder
                    e qual o status de sua operação. Esse acompanhamento em
                    tempo real proporciona uma experiência mais transparente e
                    tranquila.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    Atendimento especializado e suporte dedicado
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    O suporte 100% especializado é nossa marca registrada, com
                    atendimento dedicado para auxiliar o cliente em qualquer
                    dúvida durante a transação. Esse cuidado reflete nossos
                    valores, que são pautados em transparência e confiança. A
                    segurança é nossa prioridade, e cada ação da{" "}
                    <span className="quita">Quita Boletos</span> reforça esse
                    compromisso.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Com a nova identidade visual, a{" "}
                    <span className="quita">Quita Boletos</span> está ainda mais
                    moderna e funcional, pronta para oferecer parcelamentos
                    simples e flexíveis. Se você busca uma experiência
                    financeira segura, transparente e descomplicada, conte com a
                    Quita. Agora, com uma nova cara, mas com o mesmo compromisso
                    de sempre: ajudar você a viver uma vida financeira mais
                    tranquila.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
