import { useRoutes } from "react-router-dom";

import SiteRoutes from "./SiteRoutes";
import BlogRoutes from "./BlogRoutes";
import LandingPagesRoutes from "./LandingPagesRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([SiteRoutes, BlogRoutes, LandingPagesRoutes]);
}
