import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function LiberdadeDeFazerPixSemSaldoNaConta() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_LIBERDADE_DE_FAZER_PIX_SEM_SALDO_NA_CONTA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post" justifyContent="center">
        <Grid container justifyContent="center">
          <Grid
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="md"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog-img"
                  src="/site/blog/blog-liberdade-para-realizar-pix-sem-saldo.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid item xs={12} sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Liberdade para realizar PIX mesmo sem saldo na conta: descubra
                  como!
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{ fontStyle: "italic", mt: 2 }}>
                    Nos últimos anos, o PIX revolucionou a forma como fazemos
                    pagamentos no Brasil. Desde o seu surgimento, ele trouxe uma
                    praticidade inédita para as transações financeiras,
                    permitindo que o dinheiro seja transferido de forma
                    instantânea, a qualquer hora do dia, sete dias por semana.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Mas e quando não temos saldo suficiente na conta? A boa
                    notícia é que agora é possível realizar PIX mesmo sem saldo
                    disponível. Como? Com o PIX parcelado.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    O surgimento do PIX e a facilidade dos pagamentos
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Lançado em novembro de 2020 pelo Banco Central do Brasil, o
                    PIX rapidamente se tornou um dos meios de pagamento mais
                    populares do país. Com ele, transferências e pagamentos são
                    realizados em segundos, eliminando a necessidade de aguardar
                    dias úteis ou horários específicos. A facilidade e a
                    conveniência do PIX fizeram com que ele se tornasse uma
                    ferramenta essencial no cotidiano dos brasileiros.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    PIX parcelado no cartão de crédito
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    O PIX parcelado é uma solução inovadora que permite realizar
                    pagamentos via PIX mesmo quando não há saldo disponível na
                    conta. Nessa modalidade, o valor do pagamento é parcelado no
                    cartão de crédito, proporcionando mais flexibilidade e
                    controle sobre as finanças.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    Benefícios do PIX parcelado
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Optar pelo PIX parcelado pode trazer uma série de
                    benefícios, especialmente em momentos de aperto financeiro.
                    Veja alguns deles:
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    <strong>Mais tempo para pagar:</strong> Ao parcelar o PIX,
                    você ganha mais tempo para organizar seu orçamento e quitar
                    o valor de forma mais confortável.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    <strong>Flexibilidade:</strong> O parcelamento oferece maior
                    flexibilidade, permitindo que você distribua o pagamento em
                    várias parcelas, de acordo com sua capacidade financeira.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    <strong>Evita inadimplência:</strong> Ao optar pelo
                    parcelamento, você evita a inadimplência, mantendo suas
                    contas em dia.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    <strong>Organização financeira:</strong> Parcelar o PIX pode
                    ajudar a manter suas finanças organizadas, evitando a
                    sobrecarga de despesas em um único momento. O PIX parcelado
                    é uma excelente opção para momentos em que você precisa
                    realizar um pagamento, mas não tem o saldo disponível na
                    conta. Seja para quitar uma dívida, fazer uma compra urgente
                    ou simplesmente manter as finanças sob controle, essa
                    modalidade oferece a liberdade de pagar de forma parcelada,
                    sem complicações.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    Parcelamento de PIX com a{" "}
                    <span className="quita">Quita</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Na <span className="quita">Quita</span>, entendemos a
                    importância de ter flexibilidade nas suas finanças. Por
                    isso, oferecemos a opção de parcelar seu pagamento via PIX
                    em até 12x no cartão de crédito. Com isso, você ganha mais
                    tempo para pagar, evita a inadimplência e garante uma melhor
                    organização do seu orçamento.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Nosso objetivo é descomplicar suas obrigações financeiras,
                    oferecendo soluções seguras, práticas e acessíveis. Com o
                    PIX parcelado da{" "}
                    <span className="quita">Quita Boletos</span>, você pode se
                    sentir mais tranquilo, garantindo mais tempo e controle
                    sobre suas finanças, mesmo quando o saldo na conta não está
                    disponível.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                align="center"
                className="botao-simulacao-blog">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
