import CookieConsent from "react-cookie-consent";

export default function CookieBanner() {
  return (
    <CookieConsent
      acceptOnScroll={true}
      acceptOnScrollPercentage={20}
      location="bottom"
      buttonText="Aceitar"
      declineButtonText="Rejeitar"
      cookieName="quitaCookies"
      containerClasses="cookie-banner"
      style={{
        width: "auto",
        color: "#2B373B",
        borderRadius: "4px",
        background: "#FFF ",
        margin: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
        fontSize: "16px",
      }}
      buttonStyle={{
        display: "flex",
        alignContent: "center",
        background: "#104501",
        color: "#d8ff57",
        padding: "10px",
        borderRadius: "5px",
        fontSize: "14px",
      }}>
      A Quita Boletos utiliza cookies para aprimorar sua navegação no site.
      {"  "}
      <span>
        Para saber mais acesse nossa{" "}
        <a
          style={{ color: "#104501" }}
          rel="noreferrer"
          target="_blank"
          href="https://quitaboletos.com.br/politica-privacidade.html">
          Politica de privacidade
        </a>
      </span>
    </CookieConsent>
  );
}
