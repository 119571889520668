import { Helmet } from "react-helmet";
import { direcionaParaApp } from "@/services/utils";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import LoginIcon from "@mui/icons-material/Login";
import TagFacesOutlinedIcon from "@mui/icons-material/TagFacesOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import BotaoPrincipal from "@/components/BotaoPrincipal";
import "./SobreNos.scss";

export default function SobreNos() {
  return (
    <>
      <Container className="sessao-sobre-nos sobre-nos-1" component="main">
        <Helmet>
          <title>Sobre nós</title>
          <link rel="canonical" href="https://quitaboletos.com.br/sobre-nos" />
          <meta
            name="description"
            content="Parcele PIX ou boleto em até 12x no cartão de crédito"
          />
        </Helmet>
        <Grid container sx={{ p: { xs: 1, md: 3 } }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} md={7}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: "2.5rem", md: "3.5rem" },
                  fontWeight: "900",
                }}>
                E se houvesse uma maneira de viver o presente com mais
                tranquilidade?
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="texto-motivacao">
              A Quita Boletos nasceu do desejo de ajudar os brasileiros que
              desejam organizar as finanças, rever o planejamento financeiro e
              parcelar dívidas com mais flexibilidade e sem burocracias
              exageradas.
            </Typography>
            <Typography className="texto-motivacao">
              Entendemos os desafios que enfrentamos para sobreviver, matando um
              leão por dia, e isso deveria ser o suficiente. Nos comprometemos
              em transformar a sua rotina, por meio de soluções flexíveis, com a
              transparência e segurança que você precisa.
            </Typography>
            <Typography className="texto-motivacao">
              Algumas ocasiões merecem que você esteja 100% presente, sem
              nenhuma preocupação com contas ou boletos a vencer. Em uma rotina
              tão corrida, nós te convidamos a desacelerar. Ficar de boa para
              curtir o agora faz parte de uma vida equilibrada.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Grid sx={{ width: { xs: "100%", md: "50%" } }}>
              <video
                className="video-sobre-nos"
                controls
                poster="/site/poster-video.png"
                width={"100%"}
                height={"100%"}>
                <source
                  src="/site/video-quita-boletos.mp4"
                  type="video/mp4"></source>
              </video>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className="sessao-sobre-nos sobre-nos-2" component="section">
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "2rem", md: "4rem" },
                color: "#104501",
                fontWeight: "bold",
              }}>
              Viver no presente com a Quita fica fácil.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontSize: { xs: "1.5rem", md: "2rem" },
                fontWeight: "bold",
              }}>
              Nossos valores:
            </Typography>
          </Grid>

          <Grid item xs={12} className="valores">
            <Grid item xs={12} className="item-valores">
              <ExpandCircleDownOutlinedIcon
                sx={{ transform: "rotate(-90deg)", mr: 2 }}
              />
              <Typography sx={{ fontSize: "1.2rem" }}>
                Nosso pensamento é focado em possibilitar uma vida financeira
                mais flexível e leve.
              </Typography>
            </Grid>
            <Grid item xs={12} className="item-valores">
              <ExpandCircleDownOutlinedIcon
                sx={{ transform: "rotate(-90deg)", mr: 2 }}
              />
              <Typography sx={{ fontSize: "1.2rem" }}>
                Entendemos e respondemos às necessidades individuais dos
                clientes, oferecendo suporte e orientação personalizados.
              </Typography>
            </Grid>
            <Grid item xs={12} className="item-valores">
              <ExpandCircleDownOutlinedIcon
                sx={{ transform: "rotate(-90deg)", mr: 2 }}
              />
              <Typography sx={{ fontSize: "1.2rem" }}>
                Somos comprometidos com a segurança.
              </Typography>
            </Grid>
            <Grid item xs={12} className="item-valores">
              <ExpandCircleDownOutlinedIcon
                sx={{ transform: "rotate(-90deg)", mr: 2 }}
              />
              <Typography sx={{ fontSize: "1.2rem" }}>
                Temos um olhar voltado ao futuro e em proporcionar a liberdade
                financeira.
              </Typography>
            </Grid>
            <Grid item xs={12} className="item-valores">
              <ExpandCircleDownOutlinedIcon
                sx={{ transform: "rotate(-90deg)", mr: 2 }}
              />
              <Typography sx={{ fontSize: "1.2rem" }}>
                Somos favoráveis a diversidade e contra qualquer tipo de
                discriminação.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container className="sessao-sobre-nos sobre-nos-3" component="section">
        <Grid
          maxWidth="lg"
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "1.5rem", md: "2rem" },
                fontWeight: "bold",
              }}>
              Nossos dados:
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Grid className="card-dados verde">
              <TagFacesOutlinedIcon sx={{ fontSize: "3rem" }} />
              <Typography sx={{ fontSize: "1.5rem", p: 2 }}>
                Somamos mais de 250 mil clientes atendidos
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid className="card-dados amarelo">
              <LoginIcon sx={{ fontSize: "3rem" }} />
              <Typography sx={{ fontSize: "1.5rem", p: 2 }}>
                Área de login com benefícios exclusivos
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid className="card-dados rosa">
              <ChatOutlinedIcon sx={{ fontSize: "3rem" }} />
              <Typography sx={{ fontSize: "1.5rem", p: 2 }}>
                Atendimento pelo WhatsApp com suporte dedicado
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} align="center">
            <BotaoPrincipal
              className="botao-cadastrar"
              onClick={() => direcionaParaApp(`/app/login`)}>
              CLIQUE AQUI PARA COMEÇAR
            </BotaoPrincipal>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
