import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function ParcelarContasAtravesDoCartaoDeCredito() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_PARCELAR_CONTAS_ATRAVES_DO_CARTAO_DE_CREDITO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          xs={12}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            textAlign: "justify",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ pt: 3, pb: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog"
                src="/site/blog/blog-parcelamento-atraves-do-cartao-de-credito.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
                Parcelar contas através do cartão de crédito
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">
                A alternativa tem se tornado cada vez mais comum para a
                reorganização das finanças dos brasileiros.
              </Typography>
            </Grid>

            <Grid container spacing={1} className="sessao tutorial">
              <Grid item xs={12} sx={{ mt: 2, p: 1 }}>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  Uma conta não paga rapidamente pode se tornar uma dívida e
                  levar a um cadastro como inadimplente nos órgãos de proteção
                  ao crédito, como o Serviço de Proteção do Crédito (SPC) e o
                  Serasa. Essa situação é popularmente conhecida como “nome
                  sujo” e traz consequências, como dificuldade para abrir novas
                  contas e ao acesso de crédito, o que impossibilita ou impõe
                  juros bastante altos na hora de comprar, financiar ou pedir um
                  empréstimo.
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2, p: 1 }}>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  Segundo o Serasa, em fevereiro deste ano, 72,04 milhões de
                  brasileiros estavam em situação de inadimplência. Para
                  solucionar esse problema, uma alternativa tem se popularizado:{" "}
                  <b>Parcelar contas através do cartão de crédito</b> com o
                  auxílio de uma plataforma moderna e segura, a{" "}
                  <u className="quita">Quita Boletos</u>. <br />
                </Typography>
                <Typography
                  sx={{ p: 1 }}
                  className="descricao"
                  component={"article"}>
                  <i>
                    "Nós sabemos que alguns meses são realmente mais difíceis
                    que outros, o orçamento aperta sem a gente ter previsto e
                    quitar todos os compromissos se torna um desafio, com a
                    nossa plataforma é fácil rever o planejamento e reorganizar
                    as parcelas",{" "}
                  </i>{" "}
                  explica Augusto da Maia Pedó CEO da{" "}
                  <u className="quita">Quita Boletos</u>.{" "}
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2, px: 2 }}>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  Na plataforma da <u className="quita">Quita Boletos</u> é
                  possível parcelar boletos, pagamentos via PIX e outros débitos
                  em até 12 vezes no cartão de crédito.{" "}
                  <i>
                    "Qualquer pagamento que necessite ser feito à vista é
                    transformado em uma condição na qual o cliente parcela
                    utilizando quantos cartões quiser"
                  </i>
                  , destaca Augusto. O sistema é prático e todos os dados são
                  protegidos.
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2, p: 1 }}>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  Organizar as finanças traz alívio para as famílias e facilita
                  na hora de tirar planos do papel. Além da possibilidade do
                  parcelamento de contas rotineiras como água, luz ou impostos,
                  por exemplo, com a plataforma é possível parcelar
                  investimentos.{" "}
                  <i>
                    "Muitas vezes boas ideias surgem e são barradas na questão
                    financeira, a <u className="quita">Quita Boletos</u> surge
                    exatamente para ser uma facilitadora"
                  </i>
                  , informa Pedó.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2, p: 1 }}>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  A <u className="quita">Quita Boletos</u> está no mercado desde
                  de 2021 e já atendeu mais de 250 mil clientes em todos os
                  Estados do Brasil. Atualmente são mais de 35 milhões de
                  débitos parcelados.{" "}
                  <i>
                    "É possível sair ou até mesmo evitar a inadimplência
                    realizando uma simulação na nossa <u>plataforma</u>, estamos
                    sempre à disposição para proporcionar soluções eficientes"
                  </i>
                  , conclui Augusto.
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular></BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
