import { abreSimulador } from "@/services/utils";
import { Helmet } from "react-helmet";
import { Link } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@/components/Accordion";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import BotaoFaleConosco from "@/components/BotaoFaleConosco";
import "./Perguntas.scss";

export default function Perguntas() {
  return (
    <>
      <Container className="secao-site-perguntas secao-faq" component="main">
        <Helmet>
          <title>Perguntas</title>
          <link
            rel="canonical"
            href="https://quitaboletos.com.br/como-funciona"
          />
          <meta
            name="description"
            content="Parcele PIX ou boleto em até 12x no cartão de crédito"
          />
        </Helmet>

        <Typography
          variant="h1"
          sx={{
            fontWeight: "900",
            fontSize: "2.5rem",
            mb: 3,
            color: "#104501",
          }}>
          PERGUNTAS
        </Typography>
        <Typography sx={{ fontSize: "1.5rem", mb: 3 }}>
          <strong>Com dúvidas?</strong> É só conferir que respondemos as
          perguntas mais comuns.
        </Typography>

        <Grid container maxWidth="md">
          <Grid className="item-faq" xs={12} sx={{ mb: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">Quita Boletos é confiável?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Sim, a Quita Boletos é totalmente confiável. Nosso sistema{" "}
                  <strong>utiliza diversas etapas de segurança,</strong> como
                  biometria e antifraude, para proteger suas transações. Todas
                  as informações fornecidas são criptografadas de ponta a ponta,
                  garantindo total confidencialidade durante o processo de
                  contratação. Além disso, empregamos mecanismos avançados para
                  assegurar a segurança dos seus dados.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid className="item-faq" xs={12} sx={{ mb: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Por que preciso informar meus dados pessoais na contratação?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Solicitamos seus dados pessoais para garantir a total
                  segurança da operação, tanto para a Quita Boletos quanto para
                  você, o contratante. Isso nos permite validar a transação de
                  forma segura.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid className="item-faq" xs={12} sx={{ mb: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Qual o prazo para meus débitos serem quitados?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Nosso prazo médio para quitação dos débitos é de apenas
                  algumas horas. No entanto, em alguns casos, pode levar até 48
                  horas úteis após a autorização da transação.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid className="item-faq" xs={12} sx={{ mb: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Como eu simulo o parcelamento do meu débito?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Para simular o parcelamento do seu débito, siga os passos
                  abaixo:
                </Typography>
                <ol>
                  <li className="descricao">
                    Clique em{" "}
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => abreSimulador()}>
                      <strong>aqui</strong>
                    </Link>{" "}
                    ou em <strong>SIMULAR</strong>
                  </li>
                  <li className="descricao">
                    Informe seus dados de contato e o valor que deseja parcelar.
                  </li>
                  <li className="descricao">
                    Escolha as condições de parcelamento, insira os dados do Pix
                    ou boleto e realize o pagamento.
                  </li>
                </ol>

                <Typography className="descricao">
                  Basta aguardar que iremos te informar sobre cada etapa da
                  operação.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid className="item-faq" xs={12} sx={{ mb: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Existem limites para a contratação?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Sim, o valor máximo para contratação é de R$ 10.000,00.
                </Typography>
                <Typography className="descricao">
                  Se precisar de um valor maior, você pode realizar mais de uma
                  operação ou entrar em contato conosco para assistência.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid className="item-faq" xs={12} sx={{ mb: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Quais tipos de boleto posso parcelar?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Você pode parcelar <strong>PIX</strong> ou{" "}
                  <strong>contas de luz</strong>, <strong>água</strong>,
                  <strong> telefone</strong>, <strong> aluguel</strong>,
                  <strong> condomínio</strong>,{" "}
                  <strong>prestação do carro ou moto</strong>,{" "}
                  <strong>impostos</strong>, entre outros.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid xs={12} md={6} sx={{ mt: 2 }}>
            <Typography sx={{ fontSize: "2.4rem", fontWeight: "bold", mb: 3 }}>
              Ainda tem dúvidas?{" "}
            </Typography>
            <Typography sx={{ fontSize: "1.2rem" }}>
              É só escolher um dos nossos canais de atendimento ao cliente que
              vamos oferecer todo o suporte necessário.
            </Typography>
          </Grid>
          <Grid
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <BotaoFaleConosco className="botao-fale-conosco-amarelo">
              FALE COM A GENTE AGORA
            </BotaoFaleConosco>
          </Grid>
        </Grid>
      </Container>

      <Container className="secao-site-perguntas secao-faq" component="section">
        <Grid container maxWidth="md">
          <Grid
            item
            xs={12}
            md={6}
            sx={{ textAlign: { xs: "center", md: "left" } }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "3rem", sm: "4rem" },
                mb: 3,
                color: "#104501",
              }}>
              Atendimento e suporte dedicados!
            </Typography>

            <Grid sx={{ pt: { xs: 4, md: 2 } }}>
              <img src="/site/icon-smile.png" alt="icone sorriso" />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 5 }}>
            <Typography sx={{ fontSize: "1.5rem", mb: 3 }}>
              Nosso horário de atendimento no WhatsApp é de segunda a
              sexta-feira, das 08:30 às 18:30. E aos sábados, das 09h às 12h,
              exceto feriados nacionais.
            </Typography>
            <Typography sx={{ fontSize: "1.2rem", mb: 3 }}>
              * Disponibilizamos um bot automático, especializado em responder
              às principais dúvidas, 24 horas por dia.
            </Typography>

            <BotaoFaleConosco
              className="botao-fale-conosco-verde"
              startIcon={<WhatsAppIcon color="#d8ff57" />}>
              FALE COM A GENTE AGORA
            </BotaoFaleConosco>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
