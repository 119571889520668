// project import
import Routes from "./routes";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import "@n8n/chat/style.css";
import { createChat } from "@n8n/chat";
import { registerMetadata } from "./services/metadataEvent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_SITE_QUITABOLETOS",
      url: window.location.href,
    };
    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  createChat({
    webhookUrl:
      "https://n8n.quitabank.com.br/webhook/37be03f8-bbb9-492f-9c9d-ef24181089cc/chat",
    defaultLanguage: "ptBR",
    initialMessages: ["Olá, como posso ajudar?"],
    i18n: {
      ptBR: {
        title: "Bem-vindo a Quita!",
        subtitle: "",
        footer: "",
        getStarted: "Nova conversa",
        inputPlaceholder: "Escreva aqui sua dúvida",
      },
    },
  });

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc5yWgjAAAAANZXN6dF7fci68cey63-dlIf6kcd">
      <ThemeCustomization>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </GoogleReCaptchaProvider>
  );
};

export default App;
