import * as React from "react";
import { useNavigate } from "react-router-dom";
import { abreSimulador } from "@/services/utils";
import { direcionaParaApp } from "@/services/utils";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Logo from "@/components/Logo";
import BotaoText from "@/components/BotaoText";
import BotaoSimular from "@/components/BotaoSimular";
import BotaoSecundario from "@/components/BotaoSecundario";
import LoginIcon from "@mui/icons-material/Login";
import "./Header.scss";

function Header() {
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const abrirAction = (page) => {
    handleCloseNavMenu();
    navigation(page);
  };

  const entrar = () => {
    direcionaParaApp(`/app/login`);
  };

  const scrollInPage = (section) => {
    const id = document.getElementById(`${section}`);

    if (!id) {
      abrirAction("/");
    } else {
      id.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <AppBar className="toolbar" elevation={0}>
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              color="black"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              <MenuItem
                onClick={() => {
                  abrirAction("/");
                }}>
                <Typography textAlign="center">INÍCIO</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  abrirAction("/como-funciona");
                }}>
                <Typography textAlign="center">COMO USAR</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location.href = "https://www.quitapay.com";
                }}>
                <Typography textAlign="center">PARCEIROS</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  abrirAction("/perguntas");
                }}>
                <Typography textAlign="center">PERGUNTAS</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  abrirAction("/sobre-nos");
                }}>
                <Typography textAlign="center">SOBRE NÓS</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  abrirAction("/blog");
                }}>
                <Typography textAlign="center">BLOG</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  entrar();
                }}>
                <Typography textAlign="center">ENTRAR</Typography>
              </MenuItem>
              <MenuItem onClick={() => abreSimulador()}>
                <Typography textAlign="center">SIMULAR</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}>
            <Logo
              src="/site/logo_cabecalho.png"
              alt=""
              className="logo-quita"
              onClick={() => {
                scrollInPage("inicio");
              }}
            />
          </Typography>
          <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
            <Grid item md={3}>
              <Box
                sx={{
                  pl: 1,
                }}>
                <img
                  src="/site/logo_cabecalho.png"
                  alt=""
                  className="logo-quita"
                  onClick={() => {
                    scrollInPage("inicio");
                  }}></img>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <BotaoText
                  onClick={() => {
                    scrollInPage("inicio");
                  }}
                  sx={{ my: 1, pl: 2, display: "block", fontWeight: "600" }}>
                  INÍCIO
                </BotaoText>
                <BotaoText
                  onClick={() => {
                    abrirAction("/como-funciona");
                  }}
                  sx={{ my: 1, pl: 2, display: "block", fontWeight: "600" }}>
                  COMO USAR
                </BotaoText>
                <BotaoText
                  onClick={() => {
                    window.location.href = "https://www.quitapay.com";
                  }}
                  sx={{ my: 1, pl: 2, display: "block", fontWeight: "600" }}>
                  PARCEIROS
                </BotaoText>
                <BotaoText
                  onClick={() => {
                    abrirAction("/perguntas");
                  }}
                  sx={{ my: 1, pl: 2, display: "block", fontWeight: "600" }}>
                  PERGUNTAS
                </BotaoText>
                <BotaoText
                  onClick={() => {
                    abrirAction("/sobre-nos");
                  }}
                  sx={{ my: 1, pl: 2, display: "block", fontWeight: "600" }}>
                  SOBRE NÓS
                </BotaoText>
                <BotaoText
                  onClick={() => {
                    abrirAction("/blog");
                  }}
                  sx={{ my: 1, pl: 2, display: "block", fontWeight: "600" }}>
                  BLOG
                </BotaoText>
              </Box>
            </Grid>

            <Grid item md={3}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                }}>
                <BotaoSecundario
                  startIcon={<LoginIcon />}
                  onClick={() => {
                    entrar();
                  }}
                  sx={{
                    my: 1,
                    mr: 1,
                    fontWeight: "600",
                  }}>
                  ENTRAR
                </BotaoSecundario>

                <BotaoSimular
                  sx={{
                    my: 1,
                    display: "block",
                    fontWeight: "600",
                  }}>
                  SIMULAR
                </BotaoSimular>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
