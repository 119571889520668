import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Grid, Typography } from "@mui/material";
import BotaoSimular from "@/components/BotaoSimular";

export default function PixComCartaoDeCreditoASolucaoIdeal() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_PIX_COM_CARTAO_DE_CREDITO_A_SOLUCAO_IDEAL",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog-pix-com-cartao-de-credito-a-solucao-ideal.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
                PIX com Cartão de Crédito: A solução ideal para parcelar
                pagamentos
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: 22, fontStyle: "italic", fontWeight: "bold" }}
                gutterBottom>
                O PIX se tornou uma das formas de pagamento mais rápidas e
                populares no Brasil. Ele facilita transferências e pagamentos
                instantâneos, além de ser extremamente prático. No entanto,
                muitas vezes, surgem desafios quando não temos saldo suficiente
                na conta, especialmente em compras maiores ou contas
                importantes.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22, mb: 1 }}>
                  Já imaginou se fosse possível fazer um pagamento via PIX e
                  parcelar no cartão de crédito? Pois é exatamente isso que a{" "}
                  <span className="quita">Quita</span> oferece!
                </Typography>
                <Typography sx={{ fontSize: 22, mb: 1 }}>
                  Neste post, vamos explorar como você pode usar o PIX com o
                  cartão de crédito e resolver alguns dos problemas mais comuns
                  na hora de pagar suas contas. Confira:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Problema 1: Sem saldo na conta para pagar via PIX
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Quem nunca se deparou com aquele momento em que a grana na
                  conta está curta, mas as contas continuam chegando? Muitas
                  vezes, o pagamento à vista via PIX oferece descontos
                  vantajosos, mas a falta de saldo pode ser um obstáculo.
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  A <span className="quita">Quita</span> tem a alternativa para
                  esse problema! Com o nosso parcelamento, você pode realizar o
                  pagamento via PIX usando o limite do seu cartão de crédito.
                  Dessa forma, aproveita os benefícios do pagamento à vista,
                  mesmo que não tenha saldo na conta no momento.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Problema 2: Dificuldade em administrar várias contas ao mesmo
                  tempo
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Se você tem várias contas chegando ao mesmo tempo, organizar
                  os pagamentos pode ser um verdadeiro desafio, especialmente
                  quando surgem imprevistos. O acúmulo de boletos pode gerar
                  descontrole financeiro e até resultar em atrasos.
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  A <span className="quita">Quita</span> permite que você
                  parcele todos os seus boletos e pagamentos via PIX em até 12x
                  no cartão de crédito. Assim, você consegue organizar seus
                  débitos de forma simples e prática, sem o estresse de lidar
                  com várias contas ao mesmo tempo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Problema 3: O pagamento via PIX à vista é mais barato, mas o
                  dinheiro está apertado
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Muitas lojas e prestadores de serviços oferecem descontos
                  tentadores para pagamentos via PIX, mas e quando você está sem
                  aquele dinheiro disponível de imediato?
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  A <span className="quita">Quita</span> te ajuda a pagar à
                  vista via PIX, garantindo o desconto imediato, e depois você
                  parcela esse valor no seu cartão de crédito. É como ter o
                  melhor dos dois mundos: o desconto do pagamento à vista e a
                  flexibilidade do parcelamento.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Problema 4: Falta de flexibilidade nos prazos de pagamento
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Às vezes, a vida financeira precisa de um pouco mais de
                  flexibilidade, especialmente quando surgem despesas
                  inesperadas ou maiores do que o planejado.
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Com a <span className="quita">Quita</span>, você tem a
                  flexibilidade de parcelar suas contas em até 12x, o que te dá
                  mais tempo e tranquilidade para quitar suas dívidas sem
                  comprometer o seu orçamento de uma única vez.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Por que a <span className="quita">Quita</span> é uma solução
                  segura?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Além de toda essa praticidade, a{" "}
                  <span className="quita">Quita</span> também se preocupa com a
                  segurança. Utilizamos rigorosas medidas de proteção para
                  garantir que suas informações estejam sempre seguras, e o
                  processo de parcelamento seja realizado com toda a
                  transparência que você merece. Mais de 250 mil clientes já
                  confiaram na <span className="quita">Quita</span> para
                  organizar suas finanças, e estamos aqui para fazer o mesmo por
                  você.
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Parcelar pagamentos via PIX com o cartão de crédito é uma
                  solução inovadora que traz mais flexibilidade e praticidade
                  para quem precisa. Através da{" "}
                  <span className="quita">Quita</span>, você pode resolver
                  problemas comuns como a falta de saldo imediato, a dificuldade
                  em organizar diversas contas e a necessidade de mais tempo
                  para pagar suas despesas.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Se você busca uma forma segura e acessível de parcelar seus
                  pagamentos, experimente a <span className="quita">Quita</span>{" "}
                  e veja como pode ser fácil manter suas finanças em ordem!
                  Comece agora mesmo:
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
