import { Container, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import BotaoSimular from "@/components/BotaoSimular";
import "./benefícios-do-parcelamento-de-contas.scss";

export default function BeneficiosDoParcelamento() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_BENEFICIOS_DO_PARCELAMENT_DE_CONTAS",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post" justifyContent="center">
        <Grid container>
          <Grid
            className="logo-blog"
            xs={12}
            sx={{
              p: 2,
              display: { sm: "flex" },
              justifyContent: "center",
            }}>
            <img
              className="logo-blog-img"
              src="/site/blog/logo-os-benefícios-do-parcelamento-de-contas.jpg"
              alt="logo-blog"
            />
          </Grid>

          <Grid
            item
            className="titulo"
            xs={12}
            sx={{ paddingTop: { xs: 4, sm: 0 } }}>
            <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
              Os beneficios do parcelamento de contas
            </Typography>
          </Grid>

          <Grid item className="sessao intro" component={"section"}>
            <Grid item xs={12} sm={8}>
              <Typography sx={{ fontSize: 20, mb: 2 }} className="descricao">
                No cenário financeiro brasileiro, a possibilidade de não
                considerar a totalidade de uma dívida tem se tornado cada vez
                mais comum e vantajosa para os consumidores.
              </Typography>
              <Typography sx={{ fontSize: 20, mb: 2 }} className="descricao">
                Em tempos remotos, os boletos eram sinônimo de pagamento à
                vista, vinham incutidos do bom e velho desconto, porém agora,
                com as opções de parcelamento da{" "}
                <span className="quita">Quita Boletos</span>, os brasileiros têm
                mais flexibilidade no gerenciamento de suas finanças.
              </Typography>
              <Typography sx={{ fontSize: 20, mb: 2 }} className="descricao">
                Embora as facilidades sejam gigantescas, o dilema cotidiano
                ainda é “devo parcelar minhas dívidas ou pagar à vista?”. Para
                que você possa responder esta pergunta sem mais delongas, o
                primeiro passo é identificar fatores importantes que determinam
                como anda seu orçamento.
              </Typography>
              <Typography sx={{ fontSize: 20 }} className="descricao">
                Após esta análise financeira complexa, gaste apenas 5 minutos do
                seu tempo e descubra nos tópicos abaixo os 4 benefícios de
                parcelar seus boletos, e como é bom poder contar com a “conta a
                prazo”.
              </Typography>
            </Grid>
          </Grid>

          <Grid item className="sessao intro" component={"section"}>
            <Grid item xs={12} sm={6} className="img-intro">
              <img src="/site/blog/calculando-contas.jpg" alt="contadora" />
            </Grid>
          </Grid>

          <Grid item className="sessao beneficios" component={"section"}>
            <Grid item xs={12} sm={8}>
              <Grid>
                <Typography variant="h2">
                  1. Flexibilidade financeira
                </Typography>

                <Grid ml={2}>
                  <Typography className="descricao-beneficios">
                    Uma das principais vantagens do parcelamento de boletos é a
                    flexibilidade financeira que ele proporciona.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Quando você parcela suas dívidas, o valor total é
                    distribuído, adequando-se melhor ao seu orçamento mensal.
                    Essa opção é particularmente útil para despesas mais
                    significativas, como contas de serviços essenciais, compras
                    de bens duráveis ou até mesmo emergências inesperadas.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Com o parcelamento, é possível evitar apertos financeiros e
                    manter o equilíbrio das finanças pessoais.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Além disso, é bom lembrar que na{" "}
                    <span className="quita">Quita</span>, você parcela em até 12
                    vezes com as menores taxas disponíveis no mercado.
                  </Typography>
                </Grid>
              </Grid>

              <Grid mt={3}>
                <Typography variant="h2">
                  2. Melhor planejamento e controle financeiro
                </Typography>

                <Grid sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography className="descricao-beneficios">
                    Ao optar pelo parcelamento, os consumidores têm a
                    oportunidade de melhorar o planejamento e o controle
                    financeiro.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Afinal, organização é um dos fatores primordiais que devem
                    ser vistos naquela análise que falamos anteriormente.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Assim sendo, em vez de ter que desembolsar uma quantia
                    considerável de uma só vez, o parcelamento permite
                    distribuir o valor ao longo de meses.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Com isso, é possível estabelecer um plano de pagamento mais
                    organizado e previsível, evitando surpresas e permitindo que
                    os consumidores ajustem seus gastos mensais de acordo com as
                    parcelas estipuladas.
                  </Typography>
                </Grid>
              </Grid>

              <Grid mt={3}>
                <Typography variant="h2">
                  3. Acesso a produtos e serviços de maior valor
                </Typography>

                <Grid sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography className="descricao-beneficios">
                    Outra vantagem relevante do parcelamento de boletos é o
                    acesso a produtos e serviços de maior valor.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Com a possibilidade de dividir o pagamento em parcelas,
                    torna-se viável adquirir bens de maior custo, como
                    eletrodomésticos, eletrônicos ou até mesmo investir em
                    melhorias na moradia. O parcelamento amplia o poder de
                    compra dos consumidores e possibilita a conquista de itens
                    que, de outra forma, seriam financeiramente inviáveis.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Do mesmo modo, parcelar suas contas, te ajuda a quitar
                    dívidas que estão te impedindo de se desenvolver
                    financeiramente, e nós da{" "}
                    <span className="quita">Quita</span> somos especialistas em
                    facilitar pagamentos para te ajudar na realização de sonhos.
                  </Typography>
                </Grid>
              </Grid>

              <Grid mt={3}>
                <Typography variant="h2">
                  4. Evita o acúmulo de dívidas
                </Typography>

                <Grid sx={{ ml: 2, textAlign: "justify" }}>
                  <Typography className="descricao-beneficios">
                    Quando os boletos não são pagos integralmente, podem gerar
                    juros e multas, resultando no acúmulo de dívidas.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    O parcelamento dos boletos evita esse cenário desfavorável,
                    permitindo que o consumidor negocie diretamente com o
                    emissor para regularizar sua situação financeira sem a
                    incidência de encargos extras.
                  </Typography>
                  <Typography className="descricao-beneficios">
                    Dessa forma, é possível manter o controle das dívidas e
                    evitar que se tornem um peso financeiro excessivo.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className="sessao conclusao" component={"section"}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h2" className="titulo">
                O parcelamento de boletos é uma opção cada vez mais popular no
                Brasil, oferecendo inúmeros benefícios para os consumidores.
              </Typography>
              <Typography className="descricao">
                Além da flexibilidade financeira e do melhor controle do
                orçamento, essa prática permite liberdade consciente. Portanto,
                se você busca mais liberdade e equilíbrio em suas finanças
                pessoais, considerar o parcelamento de boletos através da{" "}
                <span className="quita">Quita Boletos</span> pode ser uma
                excelente estratégia.
              </Typography>
              <Typography className="descricao">
                Aproveite essa oportunidade e faça um planejamento adequado para
                desfrutar de todos os benefícios que essa opção oferece, faça
                uma simulação e veja o tamanho da parcela ideal para você.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} className="simulacao">
            <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
