import { Container, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import BotaoSimular from "@/components/BotaoSimular";
import "./index.scss";

export default function BlackFridayComoEvitarSitesFraudulentosEGolpes() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_BLACK_FRIDAY_COMO_EVITAR_SITES_FRAUDULENTOS_E_GOLPES",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog_black_friday_como_evitar_sites_fraudulentos_e_golpes.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                Black Friday: Como evitar sites fraudulentos e compras golpe
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: 22, fontStyle: "italic" }}>
                Na era digital, comprar online é prático e conveniente, mas é
                preciso estar atento para evitar cair em golpes. Para ajudar
                você a comprar com segurança, separamos algumas dicas
                importantes! Confira:
              </Typography>
            </Grid>
            <Grid item container spacing={2} className="sessao">
              <ol className="list">
                <li>
                  <strong>Verifique o endereço do site:</strong> <br />
                  Sempre observe o link antes de inserir seus dados. Sites
                  seguros começam com “https://” e possuem um cadeado na barra
                  de endereços. Desconfie de URLs estranhas ou mal escritas –
                  elas podem ser um sinal de fraude.
                </li>
                <li>
                  <strong>Pesquise sobre a loja:</strong> <br /> Se for sua
                  primeira compra em uma loja, busque por avaliações e opiniões
                  de outros consumidores. Pesquisar a reputação da loja em sites
                  como Reclame Aqui e redes sociais pode evitar dores de cabeça.
                </li>
                <li>
                  <strong>
                    Desconfie de ofertas muito boas para serem verdade:
                  </strong>{" "}
                  <br /> Preços extremamente baixos ou condições de pagamento
                  absurdamente vantajosas são estratégias comuns de golpistas.
                  Não se deixe levar pelo impulso – confira se a loja é de
                  confiança antes de fechar a compra.
                </li>
                <li>
                  <strong>
                    Evite clicar em links de e-mails ou mensagens desconhecidas:
                  </strong>{" "}
                  <br /> Recebeu uma promoção “imperdível” por e-mail, SMS ou
                  WhatsApp? Mesmo que pareça tentadora, evite clicar diretamente
                  no link. Acesse o site oficial digitando o endereço direto no
                  navegador para ter certeza de que está no lugar certo.
                </li>
                <li>
                  <strong>Use métodos de pagamento seguros:</strong> <br />
                  Prefira pagar com cartão de crédito ou outras opções seguras
                  que ofereçam proteção contra fraudes. Além disso, evite fazer
                  transferências para contas pessoais ou pagar via Pix para
                  terceiros. Antes de realizar um Pix, sempre confira
                  atentamente os dados de quem está recebendo o valor.
                </li>
                <li>
                  <strong>Ative a autenticação em duas etapas:</strong> <br />
                  Sempre que possível, ative a autenticação em duas etapas nas
                  suas contas online. Essa camada extra de segurança pode
                  dificultar o acesso de golpistas aos seus dados, mesmo que
                  tentem invadir sua conta.
                </li>
              </ol>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22 }}>
                  Estamos sempre em busca de garantir a segurança de nossos
                  clientes em suas operações financeiras. Mantenha-se informado
                  e realize suas compras de forma segura!
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22 }}>
                  Saiba mais sobre nosso parcelamento simples e flexível
                  clicando no botão abaixo:
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
