import moment from "moment";
import { post } from "./api";

export async function registerMetadata(data, idMetadata) {
  try {
    const lastRegister = sessionStorage.getItem("REGISTER_METADATA_DATETIME");
    if (lastRegister) {
      const diffSeconds = moment().diff(moment(lastRegister), "seconds");
      if (diffSeconds < 10) {
        return;
      }
    }
    const result = await post(`public/register-metadata`, data);
    if (idMetadata) {
      sessionStorage.setItem(idMetadata, result._id);
    }
    sessionStorage.setItem("REGISTER_METADATA_DATETIME", new Date());
  } catch (e) {
    console.log(e);
  }
}
