import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Grid, Paper, Typography } from "@mui/material";
import BotaoSimular from "@/components/BotaoSimular";
import RodapeLP from "@/components/rodape-lp";
import SecaoOpiniaoClientes from "@/pages/commom/opiniao-clientes";
import "./index.scss";

export default function PixParcelado() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_LANDING_PAGE_PIX_PARCELADO",
      url: window.location.href,
    };
    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container
        id="pix-parcelado"
        component="main"
        className="secao-landing-page secao-1-lp">
        <Helmet>
          <title>Quita Boletos - Pix parcelado</title>
          <link rel="canonical" href="https://quitaboletos.com.br" />
          <meta
            name="description"
            content="Simule o parcelamento do seu PIX em até 12x no cartão de crédito."
          />
        </Helmet>

        <Grid container sx={{ p: 3 }} maxWidth="lg">
          <Grid item xs={12} md={7}>
            <Typography
              variant="h1"
              sx={{ fontSize: { xs: "2rem", sm: "4rem" }, pt: 2 }}>
              <span className="quita">Parcele PIX</span> no cartão de crédito e
              tenha seu pagamento realizado{" "}
              <span className="quita">na hora*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography className="texto-secundario">
              Parcelamento <span className="quita">em até 12x</span> no cartão
              de crédito. <span className="quita">Ganhe mais tempo</span> para
              pagar!
            </Typography>
          </Grid>

          <Grid item xs={12} sm={7} className="botao-container">
            <BotaoSimular className="botao-parcelar">
              PARCELE SEU PIX AGORA
            </BotaoSimular>
          </Grid>

          <Grid item xs={6}>
            <Typography className="aviso-pagamento">
              *O pagamento é realizado assim que a transação for aprovada. Você
              será informado sobre cada etapa do processo.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container component="section" className="secao-landing-page secao-2-lp">
        <Grid container sx={{ p: { xs: 0, md: 3 }, m: "auto" }} maxWidth="lg">
          <Grid item xs={12} md={6} sx={{ mb: "1rem", px: { xs: 2, md: 0 } }}>
            <Grid
              title="Clique aqui para falar conosco"
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=5555999042233&text=Ol%C3%A1. Quero tirar dúvidas sobre o parcelamento de PIX!"
                )
              }
              className="logo-atendimento">
              <Paper elevation={3} className="card-logo">
                <img
                  src="/landing-pages/pix-parcelado/whatsapp-logo.svg"
                  alt="icone whatsapp atendimento"
                />
              </Paper>
            </Grid>

            <Grid className="card-atendimento">
              <Typography className="texto">
                Atendimento via WhatsApp
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} sx={{ my: "1rem", px: { xs: 2, md: 0 } }}>
            <Typography sx={{ fontSize: "2rem" }}>
              Caso o pagamento não seja autorizado de imediato,{" "}
              <span className="quita">nossa equipe entrará em contato</span>{" "}
              para oferecer suporte.
            </Typography>
            <Typography sx={{ fontSize: "1rem" }}>
              *Informe seu contato de WhatsApp atualizado para que possamos
              mantê-lo informado sobre todas as etapas do pagamento.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2, mx: 1 }}>
            <Grid item xs={12} className="card-anuncio">
              <Grid item xs={12}>
                <Typography className="titulo-anuncio">
                  <b className="quita">Sem saldo</b> na conta?{" "}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="titulo-anuncio">
                  <b className="quita">A Quita simplifica!</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="texto-anuncio">
                  <span className="destaque">Segurança</span> e{" "}
                  <span className="destaque">agilidade</span> para manter suas
                  contas em dia!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container component="section" className="secao-landing-page secao-3-lp">
        <Grid
          container
          sx={{ p: { xs: 0, md: 3 }, m: { xs: 0, md: "auto" } }}
          maxWidth="lg">
          <Grid item md={6} sx={{ display: { xs: "none", md: "flex" } }}>
            <img alt="" src="/landing-pages/pix-parcelado/banner-secao-3.png" />
          </Grid>
          <Grid item xs={12} md={6} sx={{ margin: { xs: 0, md: "auto" } }}>
            <Typography className="titulo">
              <span className="quita">Quem</span> pode usar o{" "}
              <span className="quita">PIX parcelado?</span>
            </Typography>

            <Typography className="descricao">
              Se você tem um <b>cartão de crédito</b>, pode usar nosso serviço!{" "}
              <b>O PIX parcelado é para todos</b> que precisam de mais
              flexibilidade no orçamento.
            </Typography>

            <Grid
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
              }}>
              <BotaoSimular className="botao-parcelar">
                FAÇA UMA SIMULAÇÃO
              </BotaoSimular>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container
        component="section"
        className="secao-landing-page secao-clientes-lp">
        <SecaoOpiniaoClientes />
      </Container>

      <Container component="section" className="secao-landing-page secao-4-lp">
        <Grid container spacing={2} sx={{ p: { xs: 0, md: 3 } }} maxHeight="md">
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            {!isMobile ? (
              <img
                alt=""
                src="/landing-pages/pix-parcelado/banner-secao-4.png"
              />
            ) : (
              <img
                alt=""
                src="/landing-pages/pix-parcelado/banner-secao-4.png"
              />
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2} maxWidth="lg">
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}>
                <Typography className="titulo">
                  Organize suas finanças agora!
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="texto">
                  <span className="quita">Não deixe as contas apertarem.</span>{" "}
                  Parcelar PIX no cartão de crédito é a solução rápida e
                  descomplicada que você precisa. Clique no botão abaixo e
                  comece a parcelar agora!
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}>
                <BotaoSimular className="botao-parcelar-agora">
                  QUERO PARCELAR AGORA
                </BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container component="section" className="secao-landing-page secao-5-lp">
        <Grid
          container
          spacing={2}
          sx={{ p: { xs: 0, md: 3 } }}
          className="container-secao-5">
          <Grid item xs={12}>
            <Typography
              color="primary"
              sx={{
                fontSize: "3rem",
                fontWeight: "bold",
                textAlign: "center",
              }}>
              Como funciona o Pix parcelado?
            </Typography>
          </Grid>
          <Grid item xs={12} className="card-explicacao">
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} className="container-titulo-explicacao">
                <Typography color="primary" className="texto">
                  Simples e rápido:
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography sx={{ fontSize: "1.5rem" }}>
                  Envie um valor pelo PIX e, ao invés de pagar tudo de uma vez,
                  <strong>parcele em até 12x</strong> no seu cartão de crédito.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="card-explicacao">
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={12} md={5} className="container-titulo-explicacao">
                <Typography color="primary" className="texto">
                  Sem burocracia:
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography sx={{ fontSize: "1.5rem" }}>
                  Todo o processo é <strong>online</strong>, sem complicações.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="card-explicacao">
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} className="container-titulo-explicacao">
                <Typography color="primary" className="texto">
                  Flexível:
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography sx={{ fontSize: "1.5rem" }}>
                  Alinhe seus pagamentos com seu planejamento financeiro e
                  ajuste suas despesas{" "}
                  <strong>de acordo com o que cabe no seu bolso.</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container component="section" className="secao-landing-page secao-6-lp">
        <Grid
          container
          spacing={2}
          sx={{ p: { xs: 0, md: 3 } }}
          maxWidth="lg"
          className="container-secao-6">
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <Typography className="titulo">
                <b>Por que</b> escolher o PIX parcelado com a Quita?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                className="vector-secao-6"
                alt=""
                src="/landing-pages/pix-parcelado/vector-secao-6.svg"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <Typography color="primary" className="titulo-vantagem">
                Praticidade:
              </Typography>
              <Typography className="descricao-vantagem">
                Realize o parcelamento do valor desejado{" "}
                <b>direto pelo nosso site.</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" className="titulo-vantagem">
                Controle financeiro:
              </Typography>
              <Typography className="descricao-vantagem">
                <b>Alivie a pressão do mês</b> e pague aos poucos.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" className="titulo-vantagem">
                Agilidade:
              </Typography>
              <Typography className="descricao-vantagem">
                O processo é super <b>rápido</b>, e o pagamento do PIX é
                confirmado na hora*.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" className="titulo-vantagem">
                Para qualquer despesa:
              </Typography>
              <Typography className="descricao-vantagem">
                Imprevistos, compras maiores ou até mesmo contas rotineiras,
                <b>tudo pode ser parcelado.</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "flex-start" },
              }}>
              <BotaoSimular className="botao-parcele-seu-pix-agora">
                PARCELE SEU PIX AGORA
              </BotaoSimular>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <RodapeLP />
    </>
  );
}
