import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { registerMetadata } from "@/services/metadataEvent";
import { isMobile } from "react-device-detect";

export default function DicasParaManterAsContasEmDia() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_5_DICAS_PARA_MANTER_AS_CONTAS_EM_DIA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog_5_dicas_para_manter_as_contas_em_dia.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                5 dicas infalíveis para manter suas contas em dia
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: 22 }}>
                Manter as contas em dia é essencial para garantir{" "}
                <strong>tranquilidade financeira</strong> e evitar o acúmulo de
                dívidas. Para ajudá-lo nessa tarefa, separamos cinco dicas
                infalíveis que vão facilitar o{" "}
                <strong>gerenciamento do seu orçamento</strong>. Confira!
              </Typography>
            </Grid>

            <Grid item container spacing={2} className="sessao">
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  1. Crie um orçamento mensal
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 18, pb: 1 }}>
                  O primeiro passo para manter suas contas em dia é entender
                  para onde está indo seu dinheiro. Crie um orçamento mensal
                  detalhando todas as suas receitas e despesas. Anote todos os
                  gastos fixos, como aluguel, água, luz, e variáveis, como
                  alimentação, transporte e lazer. Dessa forma, você terá uma
                  visão clara do seu fluxo de caixa e poderá identificar áreas
                  onde é possível economizar.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  2. Utilize aplicativos de controle financeiro
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 18 }}>
                  A tecnologia pode ser uma grande aliada na organização das
                  suas finanças. Existem diversos aplicativos de controle
                  financeiro disponíveis, que permitem registrar suas despesas,
                  categorizar gastos e gerar relatórios detalhados. Esses
                  aplicativos ajudam a monitorar seus hábitos de consumo e
                  facilitam o planejamento do orçamento.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  3. Priorize o pagamento das contas essenciais
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Nem todas as contas têm a mesma prioridade. Para evitar
                  problemas, pague primeiro as contas essenciais, como aluguel,
                  água, luz, gás e alimentação. Essas despesas são fundamentais
                  para o seu bem-estar e, caso atrasadas, podem acarretar juros
                  e até cortes nos serviços. Contas de menor prioridade, como
                  serviços de streaming ou assinaturas, podem ser ajustadas ou
                  cortadas temporariamente se necessário.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  4. Tenha uma reserva de emergência
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Imprevistos acontecem, e estar preparado para eles faz toda a
                  diferença. Ter uma reserva de emergência é crucial para lidar
                  com situações inesperadas sem comprometer o orçamento. Assim,
                  caso ocorra um imprevisto, como a perda de emprego ou uma
                  despesa médica, você terá uma margem de segurança para se
                  reorganizar financeiramente.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  5. Parcele suas dívidas
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Se você já está com contas em atraso, a melhor solução é
                  parcelar as dívidas e buscar alternativas flexíveis de
                  pagamento que caibam no seu orçamento. Realizar o parcelamento
                  pode ser um passo importante para limpar seu nome e recuperar
                  o controle financeiro.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Com a nossa solução{" "}
                  <span className="quita">Quita Boletos</span>, você parcela PIX
                  e boletos em até 12x no cartão de crédito, ganhando muito mais
                  tempo e flexibilidade para reorganizar suas finanças e manter
                  todas as contas em dia.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Manter as contas em dia exige disciplina e organização, mas
                  com essas cinco dicas infalíveis, você estará no caminho certo
                  para alcançar a tão desejada estabilidade financeira.
                  Lembre-se de revisar seu orçamento regularmente, utilizar a
                  tecnologia a seu favor, priorizar gastos essenciais,
                  renegociar dívidas quando necessário e sempre manter uma
                  reserva de emergência. Dessa forma, você poderá desfrutar de
                  uma vida financeira mais tranquila e segura.
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular></BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
