import { Container, Grid, Typography } from "@mui/material";
import { direcionaParaApp } from "@/services/utils";
import BotaoPrincipal from "@/components/BotaoPrincipal";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import "./secaoCadastro.scss";

export default function SecaoCadastro() {
  return (
    <Container className="secao-site secao-cadastro" component="section">
      <Grid
        container
        sx={{
          p: 3,
          m: "auto",
          display: { xs: "flex", md: "block" },
          justifyContent: "center",
        }}>
        <Grid item xs={12} sm={6} sx={{ p: 1 }}>
          <Typography className="titulo">Já é cliente?</Typography>
          <Typography sx={{ pt: 2, fontSize: "24px" }}>
            Acesse a área de login para aproveitar benefícios exclusivos!
          </Typography>

          <Grid className="beneficios">
            <Grid className="item-beneficio">
              <Typography className="texto">
                <ReceiptLongOutlinedIcon sx={{ mr: 2 }} />
                Cupons de descontos
              </Typography>
            </Grid>
            <Grid className="item-beneficio">
              <Typography className="texto">
                <PaidOutlinedIcon sx={{ mr: 2 }} />
                Simulação de pagamentos
              </Typography>
            </Grid>
            <Grid className="item-beneficio">
              <Typography className="texto">
                <SearchOutlinedIcon sx={{ mr: 2 }} />
                Verificação de pagamentos realizados
              </Typography>
            </Grid>
          </Grid>

          <BotaoPrincipal
            className="botao-login"
            onClick={() => direcionaParaApp(`/app/login`)}>
            FAÇA O LOGIN OU CRIE UMA CONTA
          </BotaoPrincipal>
        </Grid>
      </Grid>
    </Container>
  );
}
