import { Helmet } from "react-helmet";
import { Link, Paper } from "@mui/material";
import { abreSimulador } from "@/services/utils";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";
import SecaoCadastro from "../Inicio/secao-cadastro";
import "./ComoUsar.scss";

export default function ComoUsar() {
  return (
    <>
      <Helmet>
        <title>Como usar</title>
        <link rel="canonical" href="https://quitaboletos.com.br/como-usar" />
        <meta
          name="description"
          content="Parcele PIX ou boleto em até 12x no cartão de crédito"
        />
      </Helmet>
      <Container
        className="sessao-site-como-usar sessao-como-usar-1"
        component={"main"}>
        <Grid
          container
          spacing={3}
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Grid item xs={12} align="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h1" sx={{ mb: 4, fontWeight: "900" }}>
                Ficar de boa e parcelar com a Quita é mais fácil do que você
                imagina!
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4">
                O seu tempo vale muito e a gente sabe. Vem entender como a nossa
                solução funciona:
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} align="center">
            <Grid item xs={12} md={6.7} className="container-item">
              <Grid className="item-como-usar">
                <Typography className="subtitulo">
                  <span className="titulo-numero">1</span>
                </Typography>
              </Grid>

              <Grid className="descriao-item">
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: { xs: "20px", md: "24px" },
                  }}>
                  Simule do seu jeito:
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    pt: "0.3rem",
                    fontWeight: "bold",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                  }}>
                  Você pode simular sem custos, clicando{" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      abreSimulador();
                    }}>
                    AQUI
                  </Link>{" "}
                  ou em SIMULAR. <br />
                  Escola o valor total e a quantidade de parcelas que deseja
                  parcelar.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6.7} className="container-item">
              <Grid className="item-como-usar">
                <Typography className="subtitulo">
                  <span className="titulo-numero">2</span>
                </Typography>
              </Grid>
              <Grid className="descriao-item">
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: { xs: "20px", md: "24px" },
                  }}>
                  Informe os dados:
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    pt: "0.3rem",
                    fontWeight: "bold",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                  }}>
                  Depois de selecionar a opção desejada, insira os dados
                  necessários para prosseguir.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6.7} className="container-item">
              <Grid className="item-como-usar">
                <Typography className="subtitulo">
                  <span className="titulo-numero">3</span>
                </Typography>
              </Grid>
              <Grid className="descriao-item">
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: { xs: "20px", md: "24px" },
                  }}>
                  Realize o pagamento:
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    pt: "0.3rem",
                    fontWeight: "bold",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                  }}>
                  Por fim, informe os dados do cartão de crédito, clique em
                  "Pagar agora" e prontinho! Agora é só aguardar a validação de
                  segurança!
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} align="center">
            <BotaoSimular className="botao-simular">
              CLIQUE AQUI PARA COMEÇAR
            </BotaoSimular>
          </Grid>

          <Grid item xs={12} align="center">
            <Grid
              container
              spacing={3}
              sx={{ m: { sm: "auto" }, px: { xs: 2, sm: 0 }, py: 2 }}
              maxWidth="sm"
              className="bandeiras-aceitas"
              justifyContent="center">
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: { xs: "2rem", md: "3rem" },
                    fontWeight: "bold",
                    color: "#000",
                  }}>
                  Bandeiras aceitas
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Paper elevation={3} className="card-bandeiras">
                  <Typography className="imagem">
                    <img src="/site/logo-visa.webp" alt="bandeira-mastercard" />
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} md={4}>
                <Paper elevation={3} className="card-bandeiras">
                  <Typography className="imagem">
                    <img
                      src="/site/logo-mastercard.webp"
                      alt="bandeira-mastercard"
                    />
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={6} md={4}>
                <Paper elevation={3} className="card-bandeiras">
                  <Typography className="imagem">
                    <img src="/site/logo-elo.webp" alt="bandeira-elo" />
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={6} md={4}>
                <Paper elevation={3} className="card-bandeiras">
                  <Typography className="imagem">
                    <img src="/site/logo-cabal.webp" alt="bandeira-cabal" />
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={6} md={4}>
                <Paper elevation={3} className="card-bandeiras">
                  <Typography className="imagem">
                    <img
                      src="/site/logo-american-express.webp"
                      alt="bandeira-american-express"
                    />
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} md={4}>
                <Paper elevation={3} className="card-bandeiras">
                  <Typography className="imagem">
                    <img
                      src="/site/logo-hipercard.webp"
                      alt="bandeira-hipercard"
                    />
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid className="logo-brand"></Grid>
          </Grid>
        </Grid>
      </Container>

      <SecaoCadastro />
    </>
  );
}
