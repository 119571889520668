import { registerMetadata } from "@/services/metadataEvent";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function TudoQuePrecisaSaberSobreParcelamentoPix() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_TUDO_QUE_VOCE_PRECISA_SABER_SOBRE_O_PARCELAMENTO_DE_PIX",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          xs={12}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            textAlign: "justify",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ pt: 3, pb: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog"
                src="/site/blog/blog_parcelamento_de_pix.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h1" sx={{ mt: 2 }}>
                Tudo que você precisa saber sobre o parcelamento de PIX
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">
                Em momentos de aperto financeiro, ter a possibilidade de
                parcelar um pagamento pode fazer toda a diferença. Seja para
                quitar uma conta urgente ou lidar com um imprevisto inesperado,
                o parcelamento de PIX oferece uma solução rápida e segura para
                manter suas finanças em ordem.
              </Typography>
            </Grid>

            <Grid container spacing={1} className="sessao tutorial">
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h3">Como funciona</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  Com a solução de parcelamento de <b>PIX</b> da{" "}
                  <span className="quita">Quita Boletos</span>, você pode
                  dividir o valor de um pagamento <b>PIX</b> em até{" "}
                  <b> 12 vezes no seu cartão de crédito</b>. Essa flexibilidade
                  permite que você organize suas finanças de acordo com suas
                  possibilidades, sem comprometer todo o seu orçamento de uma só
                  vez.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h3">Vantagens</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <ol className="lista">
                  <li>
                    Escolha o número de parcelas que melhor se adapta à sua
                    situação financeira.
                  </li>
                  <li>
                    Faça o parcelamento diretamente pelo nosso site, de forma
                    rápida e fácil.
                  </li>
                  <li>
                    Mantenha suas finanças sob controle, sem a necessidade de
                    comprometer sua reserva financeira.
                  </li>
                  <li>
                    Todas as transações são realizadas de forma segura e
                    protegida, garantindo a confidencialidade dos seus dados.
                  </li>
                </ol>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  Na <span className="quita">Quita Boletos</span>, estamos
                  comprometidos em oferecer as melhores soluções para ajudá-lo a
                  manter suas finanças em ordem. Com nosso parcelamento de{" "}
                  <b>PIX</b>, você pode resolver seus compromissos financeiros
                  de forma prática e sem complicações.
                </Typography>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  Nosso objetivo é ajudá-lo a superar seus desafios financeiros
                  e alcançar a estabilidade financeira.
                </Typography>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  Junte-se a milhares de clientes satisfeitos que já utilizaram
                  nosso serviço de parcelamento de <b>PIX</b>.
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h3">Importante</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: 24 }}
                  className="descricao"
                  component={"article"}>
                  O parcelamento de <b>PIX</b> é realizado somente para a conta
                  de terceiros.
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular></BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
